import { httpApps,httpAdminCompanyLocation } from "../helper/http-common";

class SelectYourDiamoiondServices {
  GetRingBuilderOpeningPage(inputData) {
    return httpApps.post("PendantBuilder/GetPendentBuilderOpeningPage", inputData);
  }
  PendantBuilderLoadDiamonds(inputData) {
    return httpApps.post(
      "PendantBuilder/PendantBuilderLoadDiamonds",
      inputData
    );
  }
  LoadCompareDiamondsList(inputData) {
    return httpApps.post("PendantBuilder/LoadCompareDiamondsList", inputData);
  }

  PendantBuilderLabGrownDiamonds(inputData) {
    return httpApps.post(
      "PendantBuilder/PendantBuilderLabGrownDiamonds",
      inputData
    );
  }

  GetAvailablePendantbuilderFilters(inputData) {
    return httpApps.post(
      "PendantBuilder/GetAvailablePendantbuilderFilters",
      inputData
    );
  }

  SendMailToRetailerForNoResults(inputData) {
    return httpApps.post(
      "PendantBuilder/SendMailToRetailerForNoResults",
      inputData
    );
  }
  GetRingBuilderMinedCompareData(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderMinedCompareData",
      inputData
    );
  }

  GetRingBuilderLabCompareData(inputData) {
    return httpApps.post("RingBuilder/GetRingBuilderLabCompareData", inputData);
  }

  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilder/RingBuilderRequestCertificate",
      inputData
    );
  }

  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }

  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post(
        "PendantBuilder/PendantBuilderLoadDiamonds",
        inputData
      );
    } else {
      return httpApps.post(
        "PendantBuilder/PendantBuilderLabGrownDiamonds",
        inputData
      );
    }
  }

  GetRingBuilderViewDetailsMineLabDiamond(inputData) {
    return httpApps.post("PendantBuilder/PendantBuilderGetData", inputData);
  }

  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
  }
  GetsPendantBuilderOptiondetails(inputData) {
    return httpApps.post("PendantBuilder/GetsPendantBuilderOptiondetails", inputData);
  }
  GetLocationDetails(inputData) {
    return httpAdminCompanyLocation.post("/Profile/LocationLoadDetails",inputData)
}
ProductTracking(inputData) {
  return httpApps.post("DiamondLink/ProductTracking", inputData);
}
}

export default new SelectYourDiamoiondServices();
