import { httpApps } from "../helper/http-common";

class ItemDetailsService {
  SaveDropHint(inputData) {
    return httpApps.post("RingBuilder/SaveDropHint", inputData);
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("RingBuilder/SaveRequestInfo", inputData);
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("RingBuilder/SaveEmailAFriend", inputData);
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("RingBuilder/SaveScheduleAViewing", inputData);
  }

  SettingItemDetailPageDropDown(inputData) {
    return httpApps.post(
      "RingBuilder/SettingItemDetailPageDropDown",
      inputData
    );
  }

  GetRingList(inputData) {
    return httpApps.post("RingBuilder/GetRingList", inputData);
  }

  GetDealerLocationData(inputData) {
    return httpApps.post("DiamondLink/GetDealerLocationData", inputData);
  }
}

export default new ItemDetailsService();
