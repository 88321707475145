import {
  SET_ACTIVE_TAB,
  SWAP_TABS,
  ADD_SETTING_VIEW_EDIT,
  REMOVE_SETTING_VIEW_EDIT,
  ADD_DIAMOND_VIEW_EDIT,
  REMOVE_DIAMOND_VIEW_EDIT,
} from "../../actions/header/types";
import SettingIcon from "../../assets/images/icons/setting__icon.svg";
import DiamondIcon from "../../assets/images/icons/diamond__icon.svg";

const initialState = {
  activeTab: "selectYourDiamond",
  tabDetails: [
    // {
    //   tabName: "Choose Your Setting",
    //   tabValue: "chooseYourSetting",
    //   imgSrc: SettingIcon,
    // },
    {
      tabInitial: "Select Your",
      tabName: "Diamonds",
      tabValue: "selectYourDiamond",
      imgSrc: DiamondIcon,
    },
  ],
  isDiamondViewEdit: false,
  isSettingViewEdit: false,
};

export default function handleHeader(headerState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      return {
        ...headerState,
        activeTab: payload,
      };
    case SWAP_TABS:
      return {
        ...headerState,
        tabDetails: payload,
      };

    case ADD_SETTING_VIEW_EDIT:
      return {
        ...headerState,
        isSettingViewEdit: true,
      };

    case REMOVE_SETTING_VIEW_EDIT:
      let settingTabDetails = headerState.tabDetails;
      for (let i = 0; i < settingTabDetails.length; i++) {
        if (settingTabDetails[i].tabValue == "chooseYourSetting") {
          settingTabDetails[i].viewEdit = null;
        }
      }
      return {
        ...headerState,
        isSettingViewEdit: false,
        tabDetails: settingTabDetails,
      };
    case ADD_DIAMOND_VIEW_EDIT:
      return {
        ...headerState,
        isDiamondViewEdit: true,
      };

    case REMOVE_DIAMOND_VIEW_EDIT:
      let diamondTabDetails = headerState.tabDetails;
      for (let i = 0; i < diamondTabDetails.length; i++) {
        if (diamondTabDetails[i].tabValue == "selectYourDiamond") {
          diamondTabDetails[i].viewEdit = null;
        }
      }
      return {
        ...headerState,
        isDiamondViewEdit: false,
        tabDetails: diamondTabDetails,
      };

    default:
      return headerState;
  }
}
