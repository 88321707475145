import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Spin, Table, Checkbox, Tooltip, Input, Pagination } from "antd";

import { Tabs, Tab } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
// import MultiRangeSlider from "../../contacts/MultiRangeSlider"
import moment from "moment";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import drophint from "../../../assets/images/choose-setting/drophint.png";
import printicon from "../../../assets/images/icons/printicon.svg";
import calender from "../../../assets/images/choose-setting/calender.svg";
import emailicon from "../../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../../assets/images/choose-setting/infoblack.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import GIA from "../../../assets/images/choose-setting/gia.jpg";
import IGI from "../../../assets/images/choose-setting/igi.jpg";
import AGS from "../../../assets/images/choose-setting/ags.jpg";
import EGL from "../../../assets/images/choose-setting/egl.jpg";
import IMG5 from "../../../assets/images/choose-setting/img5.jpg";

import leftArrow from "../../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../../assets/images/choose-setting/shareIcons_03.png";
import shareTwitter from "../../../assets/images/choose-setting/shareIcons_04.png";
import facebookLive from "../../../assets/images/facebook-like.jpg";

import DtlFacebook from "../../../assets/images/fb__icon.svg";
import DtlTwitter from "../../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../../assets/images/instragm__icon.svg";
import DtlGooglePlus from "../../../assets/images/google__plus__icon.svg";


import instaGram from "../../../assets/images/insta__icon.svg";
import { handleHideDetailsPage } from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import { NotificationManager } from "react-notifications";
import ItemDetailsService from "../../../services/item-details.service";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Close from "../../../assets/images/close.png";
import {
  handleActiveTab,
  handleRemoveDiamondViewEdit,
} from "../../../actions/header/headerActions";
import { handleViewDetailsPage } from "../../../actions/selectYourDiamond/selectYourDiamond";
import ChooseViewDetailsServices from "../../../services/choose-view-details.service";
import { handleHideEditChooseThisMounting } from "../../../actions/chooseYourEarning/chooseYourEarningActions";
import { debuggerStatement } from "@babel/types";
import colorPanelService from "../../../services/color-panel.service";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleShowViewPage,
  handleSetPage,
} from "../../../actions/PageNavigations/PageNavigations";

export const ViewDiamondDetails = ({ activeTab }) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const { Option } = Select;
  const { Search } = Input;
  const Details = useSelector(
    (state) => state.selectYourDiamondReducer.selectedRow
  );
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const currentTab = useSelector((state) => state.headerReducer);
  const dispatch = useDispatch();
  const [diamondDetails, setDiamondDetails] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [completeData, setCompleteData] = useState({});
  const [diamondInfoDetails, setDiamondInfoDetails] = useState({});
  const [viewStateLoading, setViewStateLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [blockSelect, setBlockSelect] = useState("imageFileName");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [certInfoValidation, setcertInfoDataValidation] = useState({
    nameVal: "",
    emailVal: "",
    phoneNumberVal: "",
    commentsVal: "",
  });
  const [internalUseData, setInternalUseData] = useState({
    password: "",
  });
  const [internalUseDataval, setInternalUseDataval] = useState({
    passwordval: "",
  });
  const [displayImage, setDisplayImage] = useState("");
  const [showIcons, setShowIcons] = useState(false);
  const [internalButtonSave, setInternalButtonSave] = useState(false);
  const [pinterestIcons, setPinterestIcons] = useState(true);
  const [twitterIcons, setTwitterIcons] = useState(true);
  const [facebookIcons, setFacebookIcons] = useState(true);
  const [instaGramIcons, setInstaGramIcons] = useState(true);
  const [facebookLikeIcons, setFacebookLikeIcons] = useState(true);
  const [scheduleHide, setScheduleHide] = useState(true);
  const [internalUseLinkHide, setInternalUseLinkHide] = useState(false);
  const [adressHide, setAdressHide] = useState(true);
  const [callForPrice, setCallForPrice] = useState(false);
  const [showDealerInfo, setShowDealerInfo] = useState();
  const [dealerInfo, setDealerInfo] = useState(false);
  const [internalUseModalLoading, setInternalUseModalLoading] = useState(false);
  const [internalUseModal, setInternalUseModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [shapeList, setShapeList] = useState();
  const [pageName, setPageName] = useState("");
  const [rowDetails, setRowDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [getTimedate, setGetTimeDate] = useState([]);
  const [locationDetails, setLocationDetails] = useState([]);
  const [loadingDiamondGrid, setLoadingDiamondGrid] = useState(false);

  const [locationData, setLocationData] = useState({
    locationName: "",
    phone: "",
    emailID: "",
  });
  const [locationDataLoading, setLocationDataLoading] = useState(false);

  // const [varColorObj, setVarColorObj] = useState({
  //   callToActionColor: "",
  //   hoverColor: "",
  //   columnHeaderAccentColor: "",
  //   linkColor: "",
  // });
  // const [toggle, setToggle] = useState(true);
  // const previewcolor = useSelector((state) => state.previewColorReducer);

  const [mountDetails, setMountDetails] = useState({
    image: "",
    sizeimage: "",
    facbookUrl: "",
    facebookLikeUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    pinterestUrl: ""
  });

  // const [getTimedate, setGetTimeDate] = useState([]);
  // const [locationDetails, setLocationDetails] = useState([]);
  const isSocialPage = new URLSearchParams(search).get("isSocialPage");
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const diamondId = new URLSearchParams(search).get("diamondId");
  const socialpageNo = new URLSearchParams(search).get("pageNo");
  const socialpageSize = new URLSearchParams(search).get("pageSize");
  const isAllowed = new URLSearchParams(search).get("isAllowed");
  const socialpageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");
  const sociaColorPriority = new URLSearchParams(search).get("intColorPriority");
  const [tableClassAllow, setTableClassAllow] = useState(false);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  const [similarToogle, setSimilarToogle] = useState(true);

  useEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    }
    else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }

  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
              // dispatch(handleSwitchChange());
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
  & .whole__shapes .shapes__block .image:hover {
    border: 1px solid ${varColorObj.hoverColor};
  }

  & .whole__shapes .shapes__block:hover .image {
    border: 1px solid ${varColorObj.hoverColor};
  }

  & .whole__shapes .shapes__block .image .selected {
    border: 1px solid ${varColorObj.linkColor};
  }

  & .whole__shapes .shapes__block.selected .image {
    border: 1px solid ${varColorObj.linkColor};
  }

  & .img__block .imgselct__div:hover {
    border-bottom: 2px solid ${varColorObj.hoverColor};
  }

  & .img__block .imgselct__div.selected {
    border-bottom: 2px solid ${varColorObj.linkColor};
  }

  & .selection__block .selection__box:hover {
    border: 1px solid ${varColorObj.hoverColor};
    background-color: ${varColorObj.hoverColor};
  }
  & .selection__block .selection__box.selected {
    border: 1px solid ${varColorObj.linkColor};
    background-color: ${varColorObj.linkColor};
  }

  & .ant-table-thead > tr > th {
    background: ${varColorObj.columnHeaderAccentColor} !important;
    font-family: "LexendDeca-Semibold";
    height: 50px;
    color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    line-height: 14px;
  }

  & .ant-btn-primary {
    background: ${varColorObj.callToActionColor} !important;
    border-color: ${varColorObj.callToActionColor} !important;
  }
  & .ant-btn-primary:hover {
    background: ${varColorObj.hoverColor} !important;
    border-color: ${varColorObj.hoverColor} !important;
  }

  & .primary-btn {
    background: ${varColorObj.callToActionColor} !important;
    border-radius: 5px !important;
    color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    border: none;
    padding: 0px 10px;
    height: 35px;
  }

  & .primary-btn:hover {
    background: ${varColorObj.hoverColor} !important;
    border-radius: 5px !important;
    color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    border: none;
    padding: 0px 10px;
    height: 35px;
  }

  & .ant-btn {
    background: ${varColorObj.callToActionColor} !important;
    border-radius: 5px !important;
    color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
    border: none;
    padding: 0px 10px;
    height: 35px;
  }

  & .ant-btn:hover {
    background: ${varColorObj.hoverColor} !important;
    border-radius: 5px !important;
    color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    border: none;
    padding: 0px 10px;
    height: 35px;
  }
  & .ant-input-search-button {
    background: #fff !important;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
    height: 32px;
    border-left: 0;
  }

  & .ant-input-search-button:hover {
    background: #fff !important;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
    height: 32px;
    border-left: 0;
  }
  & .linkText {
    color: ${varColorObj.linkColor};
    text-decoration: none;
    cursor: pointer;
  }

  & .linkText:hover {
    color: ${varColorObj.hoverColor};
  }

  & .iframe__block .itemdetails__btns p:hover img {
    filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
      brightness(93%) contrast(89%);
    color: ${varColorObj.hoverColor};
  }

  & .iframe__block .itemdetails__btns p:hover span {
    color: ${varColorObj.hoverColor};
  }

  & .ant-pagination-item-active {
    border: 1px solid ${varColorObj.linkColor} !important;
  }

  & .ant-pagination-item:hover {
    border: 1px solid ${varColorObj.hoverColor};
  }
  & .nav-tabs .nav-link.active {
    background: ${varColorObj.columnHeaderAccentColor} !important;
    color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
  }

  & .list_icon.active {
    border: 1px solid ${varColorObj.callToActionColor};
    background: ${varColorObj.callToActionColor} !important;
    color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
  }

  & .grid_icon.active {
    border: 1px solid ${varColorObj.callToActionColor};
    background: ${varColorObj.callToActionColor} !important;
    color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
  }

  & .grid_icon:hover {
    border: 1px solid ${varColorObj.hoverColor};
    background: ${varColorObj.hoverColor} !important;
    color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
  }

  & .list_icon:hover {
    border: 1px solid ${varColorObj.hoverColor};
    background: ${varColorObj.hoverColor} !important;
    color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
  }

  & .ant-input-search-button {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  & .ant-input-search-button:hover {
    color: rgba(0, 0, 0, 0.45) !important;
  }
`;

  /* Color Individually Ends */


  // useEffect(() => {
  //   if (previewcolor) {
  //     if (previewcolor.buttonColor) {
  //       setVarColorObj({
  //         ...varColorObj,
  //         callToActionColor: previewcolor.buttonColor.callToActionColor,
  //         hoverColor: previewcolor.buttonColor.hoverColor,
  //         columnHeaderAccentColor:
  //           previewcolor.buttonColor.columnHeaderAccentColor,
  //         linkColor: previewcolor.buttonColor.linkColor,
  //       });

  //       setToggle(previewcolor.toogle);
  //     }
  //   }
  // }, [previewcolor.toogle, toggle]);

  // CSS Variables for Color panel

  // var primaryBtn = {
  //   backgroundColor: varColorObj.callToActionColor,
  //   borderBottomLeftRadius: "5px",
  //   borderBottomRightRadius: "5px",
  //   borderTopLeftRadius: "5px",
  //   borderTopRightRadius: "5px",
  //   color: "#fff",
  //   border: "none",
  //   padding: "0px 10px",
  //   height: "35px",
  // };

  // var primaryBtnHover = {
  //   backgroundColor: varColorObj.hoverColor,
  //   borderBottomLeftRadius: "5px",
  //   borderBottomRightRadius: "5px",
  //   borderTopLeftRadius: "5px",
  //   borderTopRightRadius: "5px",
  //   color: "#fff",
  //   border: "none",
  //   padding: "0px 10px",
  //   height: "35px",
  // };

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  const columns = [
    // {
    //   title: "",
    //   dataIndex: "checkbox",
    //   width: 30,
    //   render: (item, row) => {
    //     return (
    //       <div
    //         className="action__btns"
    //         onClick={e => {
    //           e.stopPropagation();
    //         }}
    //       >
    //         <Checkbox
    //           name="checkbox"
    //           checkedChildren="Yes"
    //           unCheckedChildren="No"
    //           checked={row.checkbox == true ? true : false}
    //           onChange={e => handleOnChange(row, e, "checkbox")}
    //         />
    //       </div>
    //     );
    //   }
    // },

    {
      title: "Shape",
      dataIndex: "txtShape",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div className="shape__img">
            <img
              src={row.ImageFileName}
              alt=""
            />
            <span>{row.txtShape}</span>
          </div>
        );
      },
    },
    // {
    //   title: "SKU",
    //   dataIndex: "intDiamondId",
    //   sorter: (a, b) => {},
    //   showSorterTooltip: false
    // },
    {
      title: "Carat",
      dataIndex: "fltCarat",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Color",
      dataIndex: "txtColor",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Clarity",
      dataIndex: "txtClarity",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Cut",
      dataIndex: "txtCutGrade",
      className: "hideInMobile",
      sorter: (a, b) => { },
      showSorterTooltip: false,
    },
    // {
    //   title: "In House",
    //   dataIndex: "txtInhouse",
    //   sorter: (a, b) => {},
    //   showSorterTooltip: false,
    //   width: 150
    // },
    {
      title: "Depth",
      className: "hideInMobile",
      dataIndex: "fltDepth",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Table",
      dataIndex: "fltTable",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
    },
    {
      title: "Polish",
      dataIndex: "txtPolish",
      className: "hideInMobile",
      sorter: (a, b) => { },
      showSorterTooltip: false,
    },
    {
      title: "Sym.",
      dataIndex: "txtSymmetry",
      className: "hideInMobile",
      sorter: (a, b) => { },
      showSorterTooltip: false,
    },
    {
      title: "Measure.",
      dataIndex: "txtMeasurements",
      className: "hideInMobile",
      sorter: (a, b) => { },
      showSorterTooltip: false,
    },
    {
      title: "Cert.",
      dataIndex: "txtCertificate",
      sorter: (a, b) => { },
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {
              row.txtCertificate == "None" ?
                <span
                  onClick={() => handleOpenCertificateTab(row)}
                  className="linkText"
                >
                  {row.txtCertificate}
                </span>
                :
                <span
                  onClick={() => handleOpenCertificate(row.CERTLINK)}
                  className="linkText"
                >
                  {row.txtCertificate}
                </span>

            }
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "RealPrice",
      sorter: (a, b) => { },
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {
              callForPrice ? <span>Call</span>
                : <div>
                  {row.RealPrice == "0" ? (
                    <>
                      Call <span className="hideInMobile">For Price</span>
                    </>
                  ) : (
                      <span>
                        $
                      {parseInt(row.RealPrice)
                          .toFixed()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    )}
                </div>}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            {/* <div className="image__block">
              <img src={Info_icon} alt="" />
            </div>
            <div className="image__block">
              <img
                src={Video_icon}
                alt=""
              />
            </div> */}
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleViewDetails(row)}
              />
            </div>
          </div>
          <div className="info__tooltip d-none">
            <div className="close__div">
              <img src={Close} alt="" />
            </div>
            <p>
              <span>Depth</span>
              <span>100</span>
            </p>
            <p>
              <span>Table</span>
              <span>100</span>
            </p>
            <p>
              <span>Polish</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Symmetry</span>
              <span>Very Good</span>
            </p>
            <p>
              <span>Measurement</span>
              <span>9.80X7.56X</span>
            </p>
          </div>
        </div>
      ),
    },
  ];


  // const handleSocialLinks = (url) => {
  //   if (url.includes("https")) {
  //     window.open(url, "_blank");
  //   } else if (url.includes("http")) {
  //     window.open(url, "_blank");
  //   } else {
  //     window.open("https://" + url, "_blank");
  //   }
  // };



  useEffect(() => {
    if (isSocialPage == "True") {
      const diamondDetails = {
        shapeFilters: socialshapeFilters,
        txtCutlet: socialtxtCutlet,
        txtClarity: socialtxtClarity,
        intColorPriority: sociaColorPriority,
        pageName: socialpageName,
      };
      setDealerId(paramdealerId);
      setDiamondDetails(diamondId);
      setCompleteData(Details);
      handleGetDiamondDetails(
        paramdealerId,
        diamondId
      );
      handleGetSocialDetails(paramdealerId);
      handleGetSimilarDiamond(paramdealerId, diamondDetails, socialpageNo);
      GetLocationLoadDetails(paramdealerId);
    }
    else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          setDealerId(loginDetails.dealerId);
          if (Details) {
            if (Details.intDiamondId) {
              setDiamondDetails(Details.intDiamondId);
              setCompleteData(Details);
              if (Details.pageName) {
                // handleGetMinedDiamondDetailsData(
                //   loginDetails.dealerId,
                //   Details.intDiamondId
                // );
                handleGetDiamondDetails(
                  loginDetails.dealerId,
                  Details.intDiamondId
                );
                handleGetSocialDetails(loginDetails.dealerId);
              }
            }
          }
          handleGetSimilarDiamond(loginDetails.dealerId, Details, pageNo);
          GetLocationLoadDetails(loginDetails.dealerId);


        }
      }
    }
  }, [similarToogle]);




  const hideSpinner = () => {
    setIframeLoading(false);
  };

  const GetLocationLoadDetails = (id) => {
    let inputData = {
      dealerID: id,
    };
    setLoading(true);
    try {
      SelectYourDiamondService.GetLocationDetails(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setLocationDetails(responseData.Table);

            setLoading(false);
          } else {
            setLocationDetails([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDiamondDetails = (id, diamondId) => {
    try {
      let inputData = {
        did: diamondId,
        dealerID: id,
        countryTaxRate: "77",
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetRingBuilderViewDetailsMineLabDiamond(
        inputData
      )
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          let newRe = response.data.responseData;

          if (message == "Success") {
            setDiamondInfoDetails(responseData);
            setViewStateLoading(false);

            if (Object.keys(newRe).length > 0) {
              let arr = [];
              let obj = {};
              obj["original"] = responseData.pendantImgPath;
              obj["thumbnail"] = responseData.pendantImgPath;
              arr.push(obj);
              setMountDetails((prevState) => ({
                ...prevState,
                image: responseData.pendantImgPath,
                sizeimage: responseData.htmlLink,
                facbookUrl: responseData.faceBookLink,
                facebookLikeUrl: responseData.faceBookLike,
                twitterUrl: responseData.twitterLink,
                instagramUrl: responseData.instaLink,
                pinterestUrl: responseData.pinterestLink,
              }));
              getIpData(responseData, id);
              handleGetDealerLocationData(id, Details.dealerID);
            } else {
              setViewStateLoading(false);
            }
          } else {
            setViewStateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setViewStateLoading(false);
        });
    } catch (error) {
      console.log(error);
      setViewStateLoading(false);
    }
  };

  const getIpData = async (responseData, id) => {
    debugger;
    const diamondData = responseData;
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data.IPv4);
    handleProductTracking(diamondData, res.data.IPv4, id);
  };

  const handleProductTracking = (res, ip, id) => {
    console.log(res, ip);
    try {
      let inputData = {
        dealerID: id.toString(),
        vendorID: res.manuID.toString(),
        gfInventoryID: "",
        url: window.location.href,
        styleNumber: "",
        dealerStockNumber: "",
        retailerStockNumber: "",
        metalType: "",
        metalColor: "",
        pricetype: "",
        vendorPrice: "",
        userIPAddress: ip,
        category: "",
        price: res.price.toString(),
        vendorName: res.dealerCompany.toString(),
        apiKey: "",
        diamondID: diamondId.toString(),
      };
      debugger;
      SelectYourDiamondService.ProductTracking(inputData)
        .then((response) => {})
        .catch((error) => {});
    } catch (error) {}
  };

  const handleGetSocialDetails = (id) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      setViewStateLoading(true);
      SelectYourDiamondService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData.length > 0) {
              setPinterestIcons(responseData[0].showPinterestShare);
              setTwitterIcons(responseData[0].showTwitterShare);
              setFacebookIcons(responseData[0].showFacebookShare);
              setScheduleHide(responseData[0].showScheduleView);
              setAdressHide(responseData[0].showAddresses);
              setInstaGramIcons(responseData[0].showInstagramShare);
              setFacebookLikeIcons(responseData[0].showFacebookLike);
              setInternalUseLinkHide(responseData[0].internalLinkPB);
              setCallForPrice(responseData[0].callForPricependant);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const images = [
    {
      original: `mountDetails.image`,
      thumbnail: `mountDetails.image`,
    },
  ];

  const handleOpenCertificateTab = (row) => {
    setRequestMoreCertModal(true);
    setDiamondInfoDetails(row);
  }

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-left-nav"
        aria-label="Prev Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={leftArrow} />
      </button>
    );
  }

  function renderRightNav(onClick, disabled) {
    return (
      <button
        type="button"
        className="image-gallery-right-nav"
        aria-label="Next Slide"
        disabled={disabled}
        onClick={onClick}
      >
        <img src={rightArrow} />
      </button>
    );
  }

  // Modal Functions

  const [requestCertModal, setRequestMoreCertModal] = useState(false);

  const [emailFriendModal, setEmailFriendModal] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);

  const [isModalVisible6, setIsModalVisible6] = useState(false);
  const [viewCertModal, setviewCertModal] = useState(false);
  const [requestMoreModal, setRequestMoreModal] = useState(false);
  const [showDropModal, setShowDropModal] = useState(false);
  const [scheduleViewing, setOpenScheduleViewing] = useState(false);

  // const [requestMoreInfoModalLoading, setRequestMoreInfoModalLoading] =
  //   useState(false);
  const [requestCertModalLoading, setRequestMoreCertModalLoading] =
    useState(false);
  // const [dropHintModalLoading, setshowDropHintModalLoading] = useState(false);
  // const [emailFriendModalLoading, setEmailFriendModalLoading] = useState(false);
  // const [scheduleViewingModalLoading, setScheduleViewingModalLoading] =
  //   useState(false);
  // const [viewCertModalLoading, setviewCertModalLoading] = useState(false);

  const [certInfoData, setcertInfoData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
  });
  const [dropHintInputData, setDropHintInputData] = useState({
    name: "",
    email: "",
    recipientName: "",
    recipientEmail: "",
    giftReason: "",
    giftDeadline: "",
    personalMessage: "",
  });

  const [dropHintInputDataValidation, setDropHintInputDataValidation] =
    useState({
      nameval: "",
      emailval: "",
      recipientNameval: "",
      recipientEmailval: "",
      giftReasonval: "",
      giftDeadlineval: "",
      personalMessageval: "",
    });

  const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactPreference: "byEmail",
    personalMessage: "",
  });

  const [
    requestMoreInfoInputDataValidation,
    setRequestMoreInfoInputDataValidation,
  ] = useState({
    nameval: "",
    emailval: "",
    phoneNumberval: "",
  });

  const [emailFriendInputData, setEmailFriendInputData] = useState({
    name: "",
    email: "",
    friendName: "",
    friendEmail: "",
    personalMessage: "",
  });

  const [emailFriendInputDataValidation, setEmailFriendInputDataValidation] =
    useState({
      nameval: "",
      emailval: "",
      friendNameval: "",
      friendEmailval: "",
      personalMessageval: "",
    });

  const [diamondViewInputData, setDiamondViewInputData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
  });

  const [diamondViewInputDataValidation, setDiamondViewInputDataValidation] =
    useState({
      nameval: "",
      emailval: "",
      phoneval: "",
    });

  const [scheduleViewingInputData, setScheduleViewingInputData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    appointmentDate: "",
    appointmentTime: "",
    address: "",
    message: "",
  });

  const [
    scheduleViewingInputDataValidation,
    setScheduleViewingInputDataValidation,
  ] = useState({
    nameval: "",
  });

  const showCertInfoModal = () => {
    setRequestMoreCertModal(true);
  };
  const closeCertInfoModal = () => {
    setRequestMoreCertModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
    });
    setcertInfoDataValidation({
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
      commentsVal: "",
    });
  };

  const showCertModal = () => {
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setviewCertModal(false);
  };

  const closeEmailFriendModal = () => {
    setEmailFriendModal(false);
    setEmailFriendInputData({
      name: "",
      email: "",
      friendName: "",
      friendEmail: "",
      personalMessage: "",
    });
    setEmailFriendInputDataValidation({
      nameval: "",
      emailval: "",
      friendNameval: "",
      friendEmailval: "",
      personalMessageval: "",
    });
  };

  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  const closeScheduleViewingModal = () => {
    setOpenScheduleViewing(false);
    setScheduleViewingInputData({
      name: "",
      email: "",
      phoneNumber: "",
      location: "",
      appointmentDate: "",
      appointmentTime: "",
      address: "",
      message: "",
    });
    setScheduleViewingInputDataValidation({
      nameval: "",
    });
  };

  const handleCancel6 = () => {
    setIsModalVisible6(false);
  };

  // const handleBackButton = (e) => {
  //   dispatch(handleHideDetailsPage());
  //   dispatch(handleRemoveDiamondViewEdit());
  // };


  const handleBackButton = (e) => {
    var newpageName =
      pageName == "Lab"
        ? "Lab"
        : pageName == "Mined"
          ? "Mined"
          : pageName;

    dispatch(handleSetPage(newpageName));
    if (pageName == "Lab") {
      navigate("/Lab" + `?DealerLink=${paramdealerId}`);
    } else {
      navigate("/Mined" + `?DealerLink=${paramdealerId}`);
    }
    // dispatch(handleComaparePageIds(""));
    dispatch(handleHideDetailsPage());
    dispatch(handleRemoveDiamondViewEdit());
  };

  const hideDropHintModal = () => {
    setShowDropModal(false);
    setDropHintInputData({
      name: "",
      email: "",
      recipientName: "",
      recipientEmail: "",
      giftReason: "",
      giftDeadline: "",
      personalMessage: "",
    });
    setDropHintInputDataValidation({
      nameval: "",
      emailval: "",
      recipientNameval: "",
      recipientEmailval: "",
      giftReasonval: "",
      giftDeadlineval: "",
      personalMessageval: "",
    });
  };

  const closeRequestMoreModal = () => {
    setRequestMoreModal(false);
    setRequestMoreInfoInputData({
      name: "",
      email: "",
      phoneNumber: "",
      contactPreference: "byEmail",
      personalMessage: "",
    });
    setRequestMoreInfoInputDataValidation({
      nameval: "",
      emailval: "",
      phoneNumberval: "",
      contactPreferenceval: "",
      personalMessageval: "",
    });
  };

  const handleSubmitSheduleViewing = () => {
    handleSaveScheduleViewing();
  };

  const handleDropHintChange = (e, name, type) => {
    setDropHintInputData({
      ...dropHintInputData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "recipientName") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientNameval: "",
      }));
    }
    if (name == "recipientEmail") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        recipientEmailval: "",
      }));
    }
    if (name == "giftReason") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftReasonval: "",
      }));
    }
    if (name == "personalMessage") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
    if (name == "giftDeadline") {
      setDropHintInputDataValidation((prevdata) => ({
        ...prevdata,
        giftDeadlineval: "",
      }));
    }
  };

  const handlepasswordMoreInfoChange = (e, name, type) => {
    setInternalUseData({
      ...internalUseData,
      [name]: e.target.value,
    });

    if (name == "password") {
      setInternalUseDataval((prevdata) => ({
        ...prevdata,
        passwordval: "",
      }));
    }
  };

  const handleImageSet = (data) => {
    setDisplayImage(data);
  };

  const showModal1 = () => {
    setRequestMoreModal(true);
  };

  const showModal2 = () => {
    setShowDropModal(true);
  };
  const showModal3 = () => {
    setEmailFriendModal(true);
  };
  const showModal5 = () => {
    setOpenScheduleViewing(true);
  };

  const handleSaveDropHint = async () => {

    var isValid = handleValidationDrop();

    if (!isValid) {
      setShowDropModal(true);
      return false;
    }

    let inputData = {
      loadFromMasterlink: true,
      strDiamondLinkURL: "",
      dealerID: dealerId,
      hintYourEmail: dropHintInputData.email,
      hintYourName: dropHintInputData.name,
      hintRecptEmail: dropHintInputData.recipientEmail,
      hintRecptName: dropHintInputData.recipientName,
      hintRecptReason: dropHintInputData.giftReason,
      hintRecpMessage: dropHintInputData.personalMessage,
      hintRecptGiftDeadline: dropHintInputData.giftDeadline,
      sid: "",
      did: diamondDetails,
      shape: "",
      ctw: "",
      step: "",
      themeName: "WhiteTheme",
      viewThisItemURL: window.location.href
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderDropHint(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setShowDropModal(false);
            setButtonLoading(false);
            setDropHintInputData({
              name: "",
              email: "",
              recipientName: "",
              recipientEmail: "",
              giftReason: "",
              giftDeadline: "",
              personalMessage: "",
            });
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setShowDropModal(false);
            setButtonLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "Your request has not been sent to GemFind."
          );
          setShowDropModal(false);
          setButtonLoading(false);
          setDropHintInputData({
            name: "",
            email: "",
            recipientName: "",
            recipientEmail: "",
            giftReason: "",
            giftDeadline: "",
            personalMessage: "",
          });

          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setShowDropModal(false);
      setButtonLoading(false);
      setDropHintInputData({
        name: "",
        email: "",
        recipientName: "",
        recipientEmail: "",
        giftReason: "",
        giftDeadline: "",
        personalMessage: "",
      });

      console.log(error);
    }
  };

  const handleValidationDrop = () => {

    const {
      name,
      email,
      recipientName,
      recipientEmail,
      giftReason,
      personalMessage,
      giftDeadline,
    } = dropHintInputData;

    const dropHintInputDataValidation = {
      nameval: "",
      emailval: "",
      recipientNameval: "",
      recipientEmailval: "",
      giftReasonval: "",
      personalMessageval: "",
      giftDeadlineval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      dropHintInputDataValidation.nameval = "Name is Compulsory";
    } else {
      dropHintInputDataValidation.nameval = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      dropHintInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.emailval = "Email is Invalid";
    } else {
      dropHintInputDataValidation.emailval = "";
    }

    if (!recipientName.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientNameval =
        "Recipient's Name is Compulsory";
    } else {
      dropHintInputDataValidation.recipientNameval = "";
    }

    if (!recipientEmail.trim()) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval =
        "Recipient's E-Mail is Compulsory";
    } else if (!recipientEmail.match(validRegex)) {
      isValid = false;
      dropHintInputDataValidation.recipientEmailval =
        "Recipient's E-Mail is Invalid";
    } else {
      dropHintInputDataValidation.recipientEmailval = "";
    }

    if (!giftReason.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftReasonval = "Reason is Compulsory";
    } else {
      dropHintInputDataValidation.giftReasonval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      dropHintInputDataValidation.personalMessageval =
        "Personal Message is Compulsory";
    } else {
      dropHintInputDataValidation.personalMessageval = "";
    }

    if (!giftDeadline.trim()) {
      isValid = false;
      dropHintInputDataValidation.giftDeadlineval =
        "Gift Deadline is Compulsory";
    } else {
      dropHintInputDataValidation.giftDeadlineval = "";
    }

    setDropHintInputDataValidation(dropHintInputDataValidation);
    return isValid;
  };

  const handleValidationReuestInfo = () => {

    const { name, email, phoneNumber } = requestMoreInfoInputData;

    const requestMoreInfoInputDataValidation = {
      nameval: "",
      emailval: "",
      phoneNumberval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.nameval = "Name is Compulsory";
    } else {
      requestMoreInfoInputDataValidation.nameval = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      requestMoreInfoInputDataValidation.emailval = "Email is Invalid";
    } else {
      requestMoreInfoInputDataValidation.emailval = "";
    }

    if (!phoneNumber.trim()) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval =
        "Phone Number is Compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      requestMoreInfoInputDataValidation.phoneNumberval =
        "Phone number is invalid";
    } else {
      requestMoreInfoInputDataValidation.phoneNumberval = "";
    }
    setRequestMoreInfoInputDataValidation(requestMoreInfoInputDataValidation);
    return isValid;
  };

  const handleValidationSchedule = () => {

    const { name } = scheduleViewingInputData;

    const scheduleViewingInputDataValidation = {
      nameval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      scheduleViewingInputDataValidation.nameval = "Name is Compulsory";
    } else {
      scheduleViewingInputDataValidation.nameval = "";
    }

    setScheduleViewingInputDataValidation(scheduleViewingInputDataValidation);
    return isValid;
  };

  const handleSubmitDropHint = () => {
    handleSaveDropHint();
  };
  const handlesetIcons = () => {
    if (showIcons == true) {
      setShowIcons(false);
    } else {
      setShowIcons(true);
    }
  };

  const handleRequestCertificateValidation = () => {
    const { name, email, phoneNumber } = certInfoData;
    const certInfoValidation = {
      nameVal: "",
      emailVal: "",
      phoneNumberVal: "",
    };
    let isValid = true;

    if (!name) {
      isValid = false;
      certInfoValidation.nameVal = "First Name is Compulsory";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      certInfoValidation.emailVal = "Email address is invalid";
    } else {
      certInfoValidation.emailVal = "";
    }

    if (!phoneNumber) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is compulsory";
    } else if (
      (phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
      (phoneNumber.length >= 10 && phoneNumber.includes("+"))
    ) {
      certInfoValidation.phoneNumberVal = "";
    } else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
      isValid = false;
      certInfoValidation.phoneNumberVal = "Phone number is invalid";
    } else {
      certInfoValidation.phoneNumberVal = "";
    }

    if (!isValid) {
      setcertInfoDataValidation(certInfoValidation);
    }

    return isValid;
  };


  const handleSaveRequestCertificate = async () => {
    const isValid = handleRequestCertificateValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        did: diamondInfoDetails.intDiamondId,
        name: certInfoData.name,
        email: certInfoData.email,
        phone: certInfoData.phoneNumber,
        comments: certInfoData.comments,
        retailerID: diamondInfoDetails.dealerID,
      };

      setRequestMoreCertModalLoading(true);
      await SelectYourDiamondService.RingBuilderRequestCertificate(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVal: "",
              commentsVal: "",
            });
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          } else {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setcertInfoData({
              name: "",
              email: "",
              phoneNumber: "",
              comments: "",
            });
            setcertInfoDataValidation({
              nameVal: "",
              emailVal: "",
              phoneNumberVal: "",
              commentsVal: "",
            });
            setRequestMoreCertModalLoading(false);
            setRequestMoreCertModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setcertInfoData({
            name: "",
            email: "",
            phoneNumber: "",
            comments: "",
          });
          setcertInfoDataValidation({
            nameVal: "",
            emailVal: "",
            phoneNumberVal: "",
            commentsVal: "",
          });
          setRequestMoreCertModalLoading(false);
          setRequestMoreCertModal(false);
        });
    } catch (error) {
      console.log(error);
      setcertInfoData({
        name: "",
        email: "",
        phoneNumber: "",
        comments: "",
      });
      setcertInfoDataValidation({
        nameVal: "",
        emailVal: "",
        phoneNumberVal: "",
        commentsVal: "",
      });
      setRequestMoreCertModalLoading(false);
      setRequestMoreCertModal(false);
    }
  };

  const handleSubmitRequestCert = () => {
    handleSaveRequestCertificate();
    // setcertInfoData({
    //   name: "",
    //   email: "",
    //   phoneNumber: "",
    //   comments: "",
    // });
  };

  const handleRequestMoreInfoChange = (e, name, type) => {
    setRequestMoreInfoInputData({
      ...requestMoreInfoInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "phoneNumber") {
      setRequestMoreInfoInputDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberval: "",
      }));
    }
  };

  const handleCertInfoChange = (e, name, type) => {
    setcertInfoData({
      ...certInfoData,
      [name]: e.target.value,
    });
    if (name == "name") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        nameVal: "",
      }));
    }
    if (name == "email") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        emailVal: "",
      }));
    }
    if (name == "phoneNumber") {
      setcertInfoDataValidation((prevdata) => ({
        ...prevdata,
        phoneNumberVal: "",
      }));
    }
  };

  const handleSaveRequestMoreInfo = async () => {

    var isValid = handleValidationReuestInfo();

    if (!isValid) {
      setRequestMoreModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      email: requestMoreInfoInputData.email,
      phone: requestMoreInfoInputData.phoneNumber,
      byEmail:
        requestMoreInfoInputData.contactPreference == "byEmail" || "byPhone"
          ? true
          : false,
      comments: requestMoreInfoInputData.personalMessage,
      name: requestMoreInfoInputData.name,
      did: diamondDetails,
      retailerID: "",
      type: "diamond",
      viewThisItemURL: window.location.href
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderRequestMoreInfo(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind. A representative will be contacting you shortly."
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData({
              name: "",
              email: "",
              phoneNumber: "",
              contactPreference: "",
              personalMessage: "",
            });
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setButtonLoading(false);
            setRequestMoreModal(false);
            setRequestMoreInfoInputData({
              name: "",
              email: "",
              phoneNumber: "",
              contactPreference: "",
              personalMessage: "",
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "Your request has not been sent to GemFind"
          );
          setButtonLoading(false);
          setRequestMoreModal(false);
          setRequestMoreInfoInputData({
            name: "",
            email: "",
            phoneNumber: "",
            contactPreference: "",
            personalMessage: "",
          });
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setButtonLoading(false);
      setRequestMoreModal(false);
      setRequestMoreInfoInputData({
        name: "",
        email: "",
        phoneNumber: "",
        contactPreference: "",
        personalMessage: "",
      });
      console.log(error);
    }
  };

  const handleSaveEmailFriend = async () => {
    var isValid = handleValidationEmailFriend();

    if (!isValid) {
      setEmailFriendModal(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId,
      yourEmail: emailFriendInputData.email,
      tellFriendComment: emailFriendInputData.personalMessage,
      yourName: emailFriendInputData.name,
      yourFriendEmail: emailFriendInputData.friendEmail,
      yourFriendName: emailFriendInputData.friendName,
      did: diamondDetails,
      retailerID: "",
      byEmail: true,
      viewThisItemURL: window.location.href
    };
    setButtonLoading(true);

    try {
      ChooseViewDetailsServices.PendantBuilderEmailToFriend(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              "An email has been sent to your friend,Thanks for sharing!"
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData({
              name: "",
              email: "",
              friendName: "",
              friendEmail: "",
              personalMessage: "",
            });
          } else {
            NotificationManager.error(
              "An email has been not sent to your friend"
            );
            setButtonLoading(false);
            setEmailFriendModal(false);
            setEmailFriendInputData({
              name: "",
              email: "",
              friendName: "",
              friendEmail: "",
              personalMessage: "",
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(
            "An email has not been  sent to your friend,Thanks for sharing!"
          );
          setButtonLoading(false);
          setEmailFriendModal(false);
          setEmailFriendInputData({
            name: "",
            email: "",
            friendName: "",
            friendEmail: "",
            personalMessage: "",
          });
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setButtonLoading(false);
      setEmailFriendModal(false);
      setEmailFriendInputData({
        name: "",
        email: "",
        friendName: "",
        friendEmail: "",
        personalMessage: "",
      });
      console.log(error);
    }
  };

  const handleValidationEmailFriend = () => {

    const { name, email, friendName, friendEmail, personalMessage } =
      emailFriendInputData;

    const emailFriendInputDataValidation = {
      nameval: "",
      emailval: "",
      friendNameval: "",
      friendEmailval: "",
      personalMessageval: "",
    };
    let isValid = true;

    if (!name.trim()) {
      isValid = false;
      emailFriendInputDataValidation.nameval = "Name is Compulsory";
    } else {
      emailFriendInputDataValidation.nameval = "";
    }

    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.trim()) {
      isValid = false;
      emailFriendInputDataValidation.emailval = "Email is Compulsory";
    } else if (!email.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.emailval = "Email is Invalid";
    } else {
      emailFriendInputDataValidation.emailval = "";
    }

    if (!friendName.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendNameval =
        "Friend's Name is Compulsory";
    } else {
      emailFriendInputDataValidation.friendNameval = "";
    }

    if (!friendEmail.trim()) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval =
        "Friend's E-Mail is Compulsory";
    } else if (!friendEmail.match(validRegex)) {
      isValid = false;
      emailFriendInputDataValidation.friendEmailval =
        "Friend's E-Mail is Invalid";
    } else {
      emailFriendInputDataValidation.friendEmailval = "";
    }

    if (!personalMessage.trim()) {
      isValid = false;
      emailFriendInputDataValidation.personalMessageval =
        "Personal Message is Compulsory";
    } else {
      emailFriendInputDataValidation.personalMessageval = "";
    }
    setEmailFriendInputDataValidation(emailFriendInputDataValidation);
    return isValid;
  };

  const handleSubmitRequestMoreInfo = () => {
    handleSaveRequestMoreInfo();
  };

  const handleEmailFriendChange = (e, name) => {
    setEmailFriendInputData({
      ...emailFriendInputData,
      [name]: e.target.value,
    });

    if (name == "name") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
    if (name == "email") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        emailval: "",
      }));
    }
    if (name == "friendName") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendNameval: "",
      }));
    }

    if (name == "friendEmail") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        friendEmailval: "",
      }));
    }

    if (name == "personalMessage") {
      setEmailFriendInputDataValidation((prevdata) => ({
        ...prevdata,
        personalMessageval: "",
      }));
    }
  };

  const handleSubmitEmailFriend = () => {
    handleSaveEmailFriend();
  };

  const handleScheduleViewingChange = (e, name, type) => {
    if (type == "select") {
      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e,
      });

      if (name == "location") {
        handleGetDateandTime(
          dealerId,
          "",
          scheduleViewingInputData.appointmentDate,
          e
        );
        // handleGetDateandTime("720", "", "11/8/2022", "0");
        // setScheduleViewingInputData(prevState => ({
        //   ...prevState,
        //   location: e
        // }));
      }
    } else {


      setScheduleViewingInputData({
        ...scheduleViewingInputData,
        [name]: e.target.value,
      });

      if (name == "appointmentDate") {
        handleGetDateandTime(
          dealerId,
          "",
          moment(e.target.value).format("MM/DD/YYYY"),
          scheduleViewingInputData.location
        );
        // setScheduleViewingInputData(prevState => ({
        //   ...prevState,
        //   appointmentDate: moment(e.target.value).format("MM/DD/YYYY")
        // }));
      }
    }

    if (name == "name") {
      setScheduleViewingInputDataValidation((prevdata) => ({
        ...prevdata,
        nameval: "",
      }));
    }
  };

  const handleGetDateandTime = (id, theme, appoint, location) => {

    try {
      let inputData = {
        dealerLink: id,
        themeName: theme,
        appointDate: appoint,
        locationID: location.toString(),
      };
      // setLoading(true);
      ChooseViewDetailsServices.BindTimeDropDown(inputData)
        .then((response) => {

          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setGetTimeDate(responseData.responseData.HTMLDataTime.data);
            // setLoading(false);
          } else {
            setLoading(false);
            setGetTimeDate([]);
            // setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSaveScheduleViewing = async () => {
    var isValid = handleValidationSchedule();

    if (!isValid) {
      setOpenScheduleViewing(true);
      return false;
    }
    let inputData = {
      retailerID: "",
      dealerID: dealerId,
      emailChk: scheduleViewingInputData.email,
      schedulPnone: scheduleViewingInputData.phoneNumber,
      appntMsg: scheduleViewingInputData.message,
      schedulName: scheduleViewingInputData.name,
      appntDate: scheduleViewingInputData.appointmentDate,
      hndAppntTime: scheduleViewingInputData.appointmentTime,
      did: diamondDetails,
      shape: "",
      ctw: "",
      strDiamondLinkURL: "",
      step: "",
      themeName: "",
      loadFromMasterlink: true,
      viewThisItemURL: window.location.href,
      type:"Diamond",
    };
    setButtonLoading(true);
    try {
      ChooseViewDetailsServices.PendantBuilderScheduleviewing(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(
              "Your request has been sent to GemFind."
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              appointmentDate: "",
              appointmentTime: "",
              address: "",
              message: "",
            });
          } else {
            NotificationManager.error(
              "Your request has not been sent to GemFind."
            );
            setButtonLoading(false);
            setOpenScheduleViewing(false);
            setScheduleViewingInputData({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              appointmentDate: "",
              appointmentTime: "",
              address: "",
              message: "",
            });
          }
        })
        .catch((error) => {
          NotificationManager.success("Your request has been sent to GemFind.");
          setButtonLoading(false);
          setOpenScheduleViewing(false);
          setScheduleViewingInputData({
            name: "",
            email: "",
            phoneNumber: "",
            location: "",
            appointmentDate: "",
            appointmentTime: "",
            address: "",
            message: "",
          });
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Sorry we had an error");
      setButtonLoading(false);
      setOpenScheduleViewing(false);
      setScheduleViewingInputData({
        name: "",
        email: "",
        phoneNumber: "",
        location: "",
        appointmentDate: "",
        appointmentTime: "",
        address: "",
        message: "",
      });
      console.log(error);
    }
  };

  const handleValidationPassworsInfo = () => {

    const { password } = internalUseData;

    const internalUseDataval = {
      passwordval: "",
    };
    let isValid = true;

    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})"
    );

    if (!password.trim()) {
      isValid = false;
      internalUseDataval.passwordval = "Password is Compulsory";
    } else if (!password.match(strongRegex)) {
      isValid = false;
      internalUseDataval.passwordval =
        "Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character";
    } else {
      internalUseDataval.passwordval = "";
    }
    setInternalUseDataval(internalUseDataval);
    return isValid;
  };

  const handleGetDealerInformation = () => {
    var isValid = handleValidationPassworsInfo();

    if (!isValid) {
      setInternalUseModal(true);
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        diamondDealerID: diamondInfoDetails.dealerID,
        password: internalUseData.password,
        diamondID: diamondDetails,
      };
      setInternalUseModalLoading(true);
      SelectYourDiamondService.GetDealerInformation(inputData)
        .then((response) => {
          var message = response.data.responseData.passwordcheck;
          var dealerInfo = response.data.responseData.getDiamondInformation;
          if (message == false) {
            NotificationManager.error("Kindly Enter valid Details");
            setInternalUseModalLoading(false);
            setShowDealerInfo(false);
          } else {
            setShowDealerInfo(true);
            setDealerInfo(dealerInfo);
            setInternalUseModalLoading(false);
            setInternalUseData({
              password: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setInternalUseModalLoading(false);
          setInternalUseData({
            password: "",
          });
        });
    } catch (error) {
      console.log(error);
      setInternalUseModalLoading(false);
      setInternalUseData({
        password: "",
      });
    }
  };
  const handleSubmitInternalDataHide = () => {
    setInternalButtonSave(false);
    setInternalUseModal(false);
    setShowDealerInfo(false);
  };

  const HandleCallForInternal = () => {
    {

      internalButtonSave ? handleSubmitInternalDataHide() : handleSubmitInternalData()

    }
  }

  const handleSubmitInternalData = () => {
    handleGetDealerInformation();
    setInternalButtonSave(true);
    setShowDealerInfo(false);
    // setInternalUseData({
    //   password: "",
    // });
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleCancelInternalModal = () => {
    setInternalUseModal(false);
    setShowDealerInfo(false);
    setInternalUseData({
      password: "",
    });
    setInternalUseDataval({
      passwordval: "",
    });
    setInternalButtonSave(false);
  };
  const handleOpenInternalModal = () => {
    setInternalUseModal(true);
  };

  function handlePrintDiamondList() {

    window.print()

    // var disp_setting =
    //   "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    // disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    // var docprint = window.open("", "", disp_setting);
    // docprint.document.open();
    // var htmlContent = document.getElementById("printsection").innerHTML;

    // htmlContent +=
    //   '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;max-width:80px;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    // htmlContent +=
    //   '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';

    // docprint.document.write(htmlContent);
    // docprint.document.close();

    // setTimeout(() => {
    //   docprint.print();
    // }, 5000);
    // docprint.focus();
  }

  const handleCompleteYourRing = () => {
    dispatch(handleActiveTab("completeYourRing"));
  };

  const handleAddYourSetting = (details) => {
    debugger;
    let obj = details;
    dispatch(handleViewDetailsPage(obj));
    dispatch(handleHideEditChooseThisMounting());
    navigate(
      "/chooseYourSetting" +
      `?ischooseYourSetting=True&isselectyourdiamond=false&DealerID=${paramdealerId}&DiamondId=${diamondId}&pageName=${socialpageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${socialtxtCutlet}&txtClarity=${socialtxtClarity}&intColorPriority=${sociaColorPriority}`
    );
    // dispatch(handleActiveTab("chooseYourSetting"));
  };

  const handleGetSimilarDiamond = (dealerId, DiamondDetails, page) => {

    setRowDetails(DiamondDetails);
    setShapeList(DiamondDetails.shapeFilters);
    setPageName(DiamondDetails.pageName);
    // setTimeout(() => {
    handleSimilarDiamondList(
      dealerId,
      DiamondDetails,
      page,
      DiamondDetails.pageName,
      "",
      pageSize
    );
    // }, 10);

  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleSimilarDiamondList(dealerId, completeData, pageNo, pageName, "", pageSize)
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleSimilarDiamondList(dealerId, completeData, current, pageName, "", pageNewSize)
    }
  }

  const handleSimilarDiamondList = (id, DiamondDetails, page, PageName, value, pageSize) => {
    // setLoadingDiamondGrid(true);
    let a = {};

    var ValColor = 0;
    var ValClarity = 0;
    var ValCut = 0;
    if (DiamondDetails.intColorPriority == "D") {
      ValColor = 68;
    } else if (DiamondDetails.intColorPriority == "E") {
      ValColor = 69;
    } else if (DiamondDetails.intColorPriority == "F") {
      ValColor = 70;
    } else if (DiamondDetails.intColorPriority == "G") {
      ValColor = 71;
    } else if (DiamondDetails.intColorPriority == "h") {
      ValColor = 72;
    } else if (DiamondDetails.intColorPriority == "I") {
      ValColor = 73;
    } else if (DiamondDetails.intColorPriority == "J") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "K") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "L") {
      ValColor = 74;
    } else if (DiamondDetails.intColorPriority == "M") {
      ValColor = 74;
    } else {
      ValColor = 75;
    }
    var ValColorLess = ValColor - 1;
    var ValColorMore = ValColor + 1;
    var ColorList = "";
    if (ValColorLess <= 68) {
      ColorList = ValColor + "," + ValColorMore;
    } else if (ValColorMore > 77) {
      ColorList = ValColorLess + "," + ValColor;
    } else if (ValColorMore > 77 && ValColorLess <= 68) {
      ColorList = ValColor;
    } else {
      ColorList = ValColorLess + "," + ValColor + "," + ValColorMore;
    }
    if (DiamondDetails.txtClarity == "IF") {
      ValClarity = 1;
    } else if (DiamondDetails.txtClarity == "VVS1") {
      ValClarity = 2;
    } else if (DiamondDetails.txtClarity == "VVS2") {
      ValClarity = 3;
    } else if (DiamondDetails.txtClarity == "VS1") {
      ValClarity = 4;
    } else if (DiamondDetails.txtClarity == "VS2") {
      ValClarity = 5;
    } else if (DiamondDetails.txtClarity == "SI1") {
      ValClarity = 6;
    } else if (DiamondDetails.txtClarity == "SI2") {
      ValClarity = 7;
    } else if (DiamondDetails.txtClarity == "SI3") {
      ValClarity = 8;
    } else if (DiamondDetails.txtClarity == "I1") {
      ValClarity = 9;
    } else if (DiamondDetails.txtClarity == "I2") {
      ValClarity = 10;
    } else {
      ValClarity = 11;
    }
    var ClarityList = "";
    var ValClarityLess = ValClarity - 1;
    var ValClarityMore = ValClarity + 1;

    if (ValClarityLess <= 1) {
      ClarityList = ValClarity + "," + ValClarityMore;
    } else if (ValClarityMore > 10) {
      ClarityList = ValClarityLess + "," + ValClarity;
    } else if (ValClarityMore > 1 && ValClarityLess <= 10) {
      // ClarityList = ValClarity;
      ClarityList = ValClarityLess + "," + ValClarity + "," + ValClarityMore;
    } else {
      ClarityList = ValClarityMore + "," + ValClarity + "," + ValClarityLess;
    }
    if (DiamondDetails.txtCutGrade == "Ideal") {
      ValCut = 1;
    } else if (DiamondDetails.txtCutGrade == "Excellent") {
      ValCut = 2;
    } else if (DiamondDetails.txtCutGrade == "Very Good") {
      ValCut = 3;
    } else if (DiamondDetails.txtCutGrade == "Good") {
      ValCut = 4;
    } else if (DiamondDetails.txtCutGrade == "Fair") {
      ValCut = 5;
    } else if (DiamondDetails.txtCutGrade == "Poor") {
      ValCut = 6;
    } else {
      ValCut = 7;
    }

    var CutList = "";
    var ValCutLess = ValCut - 1;
    var ValCutMore = ValCut + 1;

    if (ValCutLess <= 1) {
      CutList = ValCut + "," + ValCutMore;
    } else if (ValCutMore > 7) {
      CutList = "";
    } else if (ValCutMore > 1 && ValCutLess <= 7) {
      ClarityList = ValCut;
    } else {
      ClarityList = ValCutMore + "," + ValCut + "," + ValCutLess;
    }

    var CutList = "";
    try {
      let inputData;
      if (PageName == "Mined") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          // cutgradeMin: "1",
          // cutgradeMax: "6",
          // cutGradeList: "",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          // isMLDL: "0",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          // cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          sOrigin: "",
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: "",
          isdiamond: 0,
          custID: "",
          // vendorID: "",
          track: "0",
          // isFB: "0",
          // parent_url: "",
          // myRetailerID: "0",
          // showAdvance: false,
          // hasVideo: "True",
          // dealerCompnayName: "",
          // themeName: "WhiteTheme",
          countryTaxRate: "",
          countryCode: "",
          isFB: 0,
        };
      }
      if (PageName == "Lab") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          // cutgradeMin: "1",
          // cutgradeMax: "6",
          // cutGradeList: "",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: "",
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          // isMLDL: "0",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          // cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          sOrigin: "",
          isdiamond: 0,
          custID: "",
          // vendorID: "",
          track: "0",
          // isFB: "0",
          // parent_url: "",
          // myRetailerID: "0",
          // showAdvance: false,
          // hasVideo: "True",
          // dealerCompnayName: "",
          // themeName: "WhiteTheme",
          countryTaxRate: "",
          countryCode: "",
          isFB: 0,
        };
      }
      if (PageName == "FancyColor") {
        inputData = {
          dealerID: id.toString(),
          shape:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          priceMin: "",
          priceMax: "",
          caratMin: "0",
          caratMax: "23.85",
          colorMin: "68",
          colorMax: "75",
          clarityMin: "1", //FROM INTIAL FILTER
          clarityMax: "3", //FROM INTIAL FILTER
          cutgradeMin: "1",
          cutgradeMax: "6",
          symmetryMin: "1",
          symmetryMax: "5",
          polishMin: "1",
          polishMax: "5",
          fluorescenceMin: "1",
          fluorescenceMax: "6",
          // cutgradeMin: "1",
          // cutgradeMax: "6",
          // cutGradeList: "",
          certificate: "",
          skuNo: value,
          pairCode: "",
          pageSize: pageSize.toString(),
          currentPage: page.toString(),
          orderBy: "SortOrder",
          orderType: "Asc",
          detailsLink: "DiamondDetail.aspx",
          dID: "",
          depthMin: "0",
          depthMax: "6390",
          tableMin: "0",
          tableMax: "98",
          caratPriceMin: "0",
          caratPriceMax: "100000",
          themeName: "whiteTheme",
          showPrice: "1",
          // isMLDL: "0",
          bindWhat: "All",
          polishList: "",
          symmetryList: "",
          fluorescenceList: "",
          extraQStr: "&SID=&Shape=&CTW=&Size=",
          // cutGradeList: CutList.toString(), //Logic from above input
          colorList: ColorList.toString(), // Logic from above input
          clarityList: ClarityList.toString(), //Logic from above input
          bindCheckbox: "1",
          sOrigin: "",
          isdiamond: 0,
          custID: "",
          // vendorID: "",
          track: "0",
          // isFB: "0",
          // parent_url: "",
          // myRetailerID: "0",
          // showAdvance: false,
          // hasVideo: "True",
          // dealerCompnayName: "",
          // themeName: "WhiteTheme",
          countryTaxRate: "",
          countryCode: "",
          isFB: 0,
          isMLPB: 0,
          shapeList:
            DiamondDetails.shapeFilters.length > 0
              ? DiamondDetails.shapeFilters.toString()
              : "",
          CutGradeList: "",
        };
      }

      setLoadingDiamondGrid(true);
      SelectYourDiamondService.GetSimilarDiamondsByPage(inputData, PageName)

        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table1;
          let newRe = response.data.responseData.Table1;
          var FirstRow = response.data.responseData.Table1[0];
          if (message == "Success") {
            if (Object.keys(newRe).length > 0) {
              setList(responseData);
              setTotalRowCount(FirstRow.intTotalRecords);
              setLoadingDiamondGrid(false);
            } else {
              setList([]);
              setTotalRowCount(0);
              setLoadingDiamondGrid(false);
            }
          } else {
            setList([]);
            setTotalRowCount(0);
            setLoadingDiamondGrid(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingDiamondGrid(false);
        });
    } catch (error) {
      console.log(error);
      setLoadingDiamondGrid(false);
    }
  };

  const handleScrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleViewDetails = (details) => {
    let obj = details;
    // obj["pageName"] = Details.pageName;
    // obj["shapeFilters"] = shapeList;
    // dispatch(handleViewDetailsPage(obj));

    navigate(
      "/viewDiamondDetails" +
      `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${socialpageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    setSimilarToogle(!similarToogle);
    handleScrollToTop();

    // handleGetDiamondDetails(paramdealerId, obj.intDiamondId);
    // handleGetSimilarDiamond(paramdealerId, obj, pageNo);
  };

  const handleShareIconsView = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleGetDealerLocationData = (id, retailerId) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        myRetailerID: retailerId,
      };
      setLoading(true);
      SelectYourDiamondService.GetDealerLocationData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          let newRe = response.data.responseData[0];

          if (message == "Success") {
            if (Object.keys(newRe).length > 0) {
              setLocationData({
                locationName: responseData.locationName,
                phone: responseData.phone,
                emailID: responseData.emailID,
              });

              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Wrapper>
        {/* <span className="back__btn" onClick={(e) => handleBackButton(e)}>
          {" "}
          {"<< Back"}{" "}
        </span> */}
        <div className="allprod_section jwlsrch__section app__preview choose__setting">
          <div className="row">
            <div className="col-md-12">
              <div className="prodetail__section">
                <div className="form__fields">
                  <div className="col-md-12">
                    <div
                      className="prod__maindiv app__preview p-0 border-0"
                      id="printsection"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="row">
                            {/* <div className="col-lg-12">
                              <div className="whole__shapes available__shapes">
                                <div
                                  className={
                                    blockSelect == "imageFileName"
                                      ? "shapes__block selected"
                                      : "shapes__block"
                                  }
                                >
                                  {diamondInfoDetails.imageFileName ? (
                                    <div className="image">
                                      <img
                                        onClick={() =>
                                          setBlockSelect("imageFileName")
                                        }
                                        src={diamondInfoDetails.imageFileName}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className={
                                    blockSelect == "certificateImage"
                                      ? "shapes__block selected"
                                      : "shapes__block"
                                  }
                                >
                                  {diamondInfoDetails.certificateImage ? (
                                    <div className="image">
                                      <img
                                        onClick={() =>
                                          setBlockSelect("certificateImage")
                                        }
                                        src={
                                          diamondInfoDetails.certificateImage
                                        }
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>

                                <div
                                  className={
                                    blockSelect == "videoFileName"
                                      ? "shapes__block selected"
                                      : "shapes__block"
                                  }
                                >
                                  {diamondInfoDetails.videoFileName ? (
                                    <div className="image">
                                      <img
                                        onClick={() =>
                                          setBlockSelect("videoFileName")
                                        }
                                        src={Video_icon}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="big__img">
                                {blockSelect == "imageFileName" ? (
                                  <img
                                    src={diamondInfoDetails.imageFileName}
                                    className="img-fluid"
                                  />
                                ) : blockSelect == "videoFileName" ? (
                                  <iframe
                                    width="100%"
                                    height="450"
                                    src={diamondInfoDetails.videoFileName}
                                  />
                                ) : blockSelect == "certificateImage" ? (
                                  <iframe
                                    width="100%"
                                    height="450"
                                    className="img-fluid"
                                    src={diamondInfoDetails.certificateImage}
                                  />
                                ) : null}
                              </div>
                            </div> */}

                            {/* <div className="Box1 d-flex mr-2">
                              <p>Diamond 1</p>
                              <div className="Box__image mr-1 ml-2">
                                <img
                                  onClick={() =>
                                    handleImageSet(mountDetails.image)
                                  }
                                  src={mountDetails.image}
                                />
                              </div>
                              <div className="Box__image">
                                <img
                                  onClick={() =>
                                    handleImageSet(mountDetails.sizeimage)
                                  }
                                  src={mountDetails.sizeimage}
                                />
                              </div>
                            </div> */}

                            <div className="whole__box">
                              <div className="diamond__block">
                                <p>Diamond 1</p>
                                <div className="image__thbox">
                                  <div className="box__one">
                                    <img
                                      onClick={() =>
                                        handleImageSet(mountDetails.image)
                                      }
                                      src={mountDetails.image}
                                    />
                                  </div>
                                  <div className="box__one">
                                    <img
                                      onClick={() =>
                                        handleImageSet(mountDetails.sizeimage)
                                      }
                                      src={mountDetails.sizeimage}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="big__img thimage__slider">
                                <img
                                  src={
                                    displayImage
                                      ? displayImage
                                      : mountDetails.image
                                  }
                                  className="img-fluid"
                                />



                                {/* <div className="share__block">
                                  {
                                    pinterestIcons == false &&
                                      twitterIcons == false &&
                                      facebookIcons == false &&
                                      instaGramIcons == false &&
                                      facebookLikeIcons == false ?
                                      <div> </div> :
                                      <div className="share__button">
                                        <img
                                          src={shareBlue}
                                          onClick={() => handlesetIcons()}
                                        />
                                      </div>
                                  }
                                  {showIcons ? (
                                    <div className="social__button">
                                      {facebookIcons ? (
                                        <img src={shareFacebook} onClick={() => handleShareIconsView(mountDetails.facbookUrl)} />
                                      ) : null}
                                      {pinterestIcons ? (
                                        <img src={sharePintrest} onClick={() => handleShareIconsView(mountDetails.pinterestUrl)} />
                                      ) : null}
                                      {twitterIcons ? (
                                        <img src={shareTwitter} onClick={() => handleShareIconsView(mountDetails.twitterUrl)} />
                                      ) : null}
                                      {instaGramIcons ? (
                                        <img src={instaGram} onClick={() => handleShareIconsView(mountDetails.instagramUrl)} />
                                      ) : null}
                                      {facebookLikeIcons ? (
                                        <img src={facebookLive} onClick={() => handleShareIconsView(mountDetails.facebookLikeUrl)} />
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div> */}

                              </div>

                            </div>


                            <div className="col-md-12">
                              <div className="diamond__detail">
                                <div className="row">
                                  <div className="col-md-12 mb-2">
                                    <div>
                                      <span>Diamond Grading Report</span>

                                      {diamondInfoDetails.certificate == "" ? (
                                        <span
                                          className="linkText ml-2"
                                          onClick={() => showCertInfoModal()}
                                        >
                                          Request Certificate
                                        </span>
                                      ) : (
                                          <span
                                            className="linkText ml-2"
                                            onClick={() => showCertModal()}
                                          >
                                            View
                                        </span>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    {diamondInfoDetails.certificate !== "" ? (
                                      diamondInfoDetails.certificate ==
                                        "GIA" ? (
                                          <img src={GIA} width="70px" />
                                        ) : diamondInfoDetails.certificate ==
                                          "IGI" ? (
                                            <img src={IGI} width="70px" />
                                          ) : diamondInfoDetails.certificate ==
                                            "GIA" ? (
                                              <img src={GIA} width="70px" />
                                            ) : diamondInfoDetails.certificate ==
                                              "AGS" ? (
                                                <img src={AGS} width="70px" />
                                              ) : diamondInfoDetails.certificate ==
                                                "EGL" ? (
                                                  <img src={EGL} width="70px" />
                                                ) : (
                                                  <img src={IMG5} width="70px" />
                                                )
                                    ) : (
                                        <img src={IMG5} width="70px" />
                                      )}
                                  </div>
                                  <div className="col-md-10">
                                    <p>
                                      This diamond is graded by{" "}
                                      {diamondInfoDetails.certificate
                                        ? diamondInfoDetails.certificate
                                        : "NA"}
                                      . This diamond certificate is your
                                      assurance that the diamond meets all
                                      gemological standards to the quality grade
                                      shown in the certification report.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="price__block text-center">
                              <h4 className="subheading">
                                US$
                                {diamondInfoDetails.price
                                  ? diamondInfoDetails.price
                                  : "NA"}
                              </h4>
                              <button className="primary-btn">
                                Add To Cart
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="prodesc__div">
                            <Tabs
                              defaultActiveKey="home"
                              id="uncontrolled-tab-example"
                              className="tab__div border-0"
                            >
                              <Tab eventKey="home" title="Item Details">
                                <Spin spinning={viewStateLoading}>
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>
                                        {diamondInfoDetails.carat}-Carat{" "}
                                        {diamondInfoDetails.cut} Diamond
                                      </h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      <div className="row top__content m-0">
                                        <div className="col-md-12 mt-3">
                                          <div className="row">
                                            <div className="col-md-9">
                                              <p>
                                                This{" "}
                                                {diamondInfoDetails.cut
                                                  ? diamondInfoDetails.cut
                                                  : "NA"}
                                                -Cut,
                                                {diamondInfoDetails.color
                                                  ? diamondInfoDetails.color
                                                  : "NA"}
                                                -color, and{" "}
                                                {diamondInfoDetails.clarity
                                                  ? diamondInfoDetails.clarity
                                                  : "NA"}
                                                -clarity diamond comes
                                                accompanied by a diamond grading
                                                report from{" "}
                                                {diamondInfoDetails.certificate
                                                  ? diamondInfoDetails.certificate
                                                  : "NA"}
                                              </p>
                                            </div>
                                            <div className="col-md-3">
                                              <p className="text-right text-left-mobile">
                                                SKU#{" "}
                                                <span className="linkText">
                                                  {diamondInfoDetails.skuNo
                                                    ? diamondInfoDetails.skuNo
                                                    : "NA"}
                                                </span>
                                                {/* <Tooltip
                                                  placement="right"
                                                  title={
                                                    <div className="row">
                                                      <div className="col-md-2">
                                                        <img
                                                          src={GIA}
                                                          width="60px"
                                                        />
                                                      </div>
                                                      <div className="col-md-10">
                                                        <p>
                                                          This diamond is graded
                                                          by GIA. This provides
                                                          you an authoritative
                                                          analysis of your
                                                          diamond. It also
                                                          verifies that your
                                                          diamond meets all the
                                                          specific quality
                                                          requirements.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  }
                                                >
                                                  <img
                                                    src={UnionIcon}
                                                    alt=""
                                                    className="ml-1"
                                                  />
                                                </Tooltip> */}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                         <div className="detailblk">
                                            <p className="font__bold">Report</p>
                                            <p>
                                              {diamondInfoDetails.certificate
                                                ? diamondInfoDetails.certificate
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                          <div className="detailblk">
                                            <p className="font__bold">Cut</p>
                                            <p>
                                              {diamondInfoDetails.cut
                                                ? diamondInfoDetails.cut
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12 mobile__version">
                                          <hr />
                                        </div>

                                        <div className="col-md-3 col-6">
                                          <div className="detailblk">
                                            <p className="font__bold">Color</p>
                                            <p>
                                              {diamondInfoDetails.color
                                                ? diamondInfoDetails.color
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                          <div className="detailblk">
                                            <p className="font__bold">
                                              Clarity
                                            </p>
                                            <p>
                                              {" "}
                                              {diamondInfoDetails.clarity
                                                ? diamondInfoDetails.clarity
                                                : "NA"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12 mobile__version">
                                          <hr />
                                        </div>

                                        <div className="col-md-12">
                                          <div className="detailblk">
                                            <p className="font__bold">
                                              {/* {callForPrice ? null : <span>US$</span> } */}
                                              {/* {callForPrice ? <span>Call For Price</span> : diamondInfoDetails.fltPrice == "0" ? <span>Call For Price</span> : diamondInfoDetails.fltPrice} */}

                                              {callForPrice ? <span>Call For Price</span> : diamondInfoDetails.fltPrice == "0" ? (
                                                <>
                                                  Call <span className="hideInMobile">For Price</span>
                                                </>
                                              ) : (
                                                  <span>
                                                    $
                                                  {parseInt(diamondInfoDetails.fltPrice)
                                                      .toFixed()
                                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                  </span>
                                                )}
                                            </p>
                                          </div>

                                          {currentTab &&
                                            currentTab.isSettingViewEdit ? (
                                              <button
                                                onClick={handleCompleteYourRing}
                                                className="primary-btn mr-2 mb-2"
                                              >
                                                Complete Your Ring
                                            </button>
                                            ) : (
                                              <button
                                                className="primary-btn mr-2 mb-2"
                                                onClick={() => handleAddYourSetting(completeData)}
                                              >
                                                Add To Your Pendant
                                            </button>
                                            )}

                                          {/* <button className="primary-btn mr-2">
                                            Buy Diamond Only
                                          </button> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="desc__maindiv">
                                      <div className="row top__content m-0">
                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal2}>
                                              <span className="img__box">
                                                <img src={drophint} />
                                              </span>
                                             <span>Drop A Hint</span>
                                            </p>
                                          </div>

                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal1}>
                                              <span className="img__box">
                                                <img src={infoblack} />
                                              </span>
                                              <span>Request More Info</span>
                                            </p>
                                          </div>

                                        </div>

                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p onClick={showModal3}>
                                              <span className="img__box">
                                                <img src={emailicon} />
                                              </span>
                                              <span>E-Mail A Friend</span>
                                            </p>
                                          </div>

                                          {scheduleHide ? (
                                            <div className="col-sm-12 col-md-6">
                                              <p onClick={showModal5}>
                                                <span className="img__box">
                                                  <img src={calender} />
                                                </span>
                                               <span>Schedule Viewing</span>
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>

                                        <div className="row itemdetails__btns p-0">
                                          <div className="col-sm-12 col-md-6">
                                            <p
                                              onClick={() => {
                                                handlePrintDiamondList();
                                              }}
                                            >
                                              <span className="img__box"><img src={printicon} /></span>
                                              <span>Print Details</span>
                                            </p>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <p>
                                            Have a question regarding this item?
                                            Our specialists are available to
                                            assist you.
                                          </p>
                                        </div>
                                        <Spin spinning={loading}>
                                          {adressHide ? (
                                            <div className="col-md-12">
                                              <p className="font__bold d-flex justify-between">
                                                <span className="font__bold">
                                                  {locationData.locationName
                                                    ? parse(
                                                      locationData.locationName
                                                    )
                                                    : ""}
                                                </span>
                                                {/* <span className="subheading">
                                            {locationData && locationData.locationName}
                                            </span> */}
                                              </p>
                                              <p className="font__bold">
                                                <span className="font__bold">
                                                  {locationData.phone
                                                    ? locationData.phone
                                                    : ""}
                                                </span>
                                              </p>
                                              <p className="font__bold">
                                                <a
                                                  href={`mailto:${locationData.emailID}`}
                                                >
                                                  {locationData.emailID
                                                    ? locationData.emailID
                                                    : ""}
                                                </a>
                                              </p>
                                            </div>
                                          ) : null}

                                        </Spin>

                                        <div className="col-md-12">
                                          <div className="dtl__socialsection">
                                            {
                                              facebookIcons ?
                                                <button className="dtl__socialbtn fb__btn">
                                                  <img src={DtlFacebook} onClick={() => handleShareIconsView(mountDetails.facbookUrl)} />
                                                </button> : null
                                            }

                                            {
                                              pinterestIcons ?
                                                <button className="dtl__socialbtn pintrest">
                                                  <img src={DtlPintrest} onClick={() => handleShareIconsView(mountDetails.pinterestUrl)} />
                                                </button>
                                                : null
                                            }

                                            {
                                              twitterIcons ?

                                                <button className="dtl__socialbtn tweeter">
                                                  <img src={DtlTwitter} onClick={() => handleShareIconsView(mountDetails.twitterUrl)} />
                                                </button> : null
                                            }
                                            {
                                              instaGramIcons ?
                                                <button className="dtl__socialbtn instagram">
                                                  <img src={DtlInstagram} onClick={() => handleShareIconsView(mountDetails.instagramUrl)} />
                                                </button> : null
                                            }
                                            {
                                              facebookLikeIcons ?
                                                <button className="dtl__socialbtn">
                                                  <img src={facebookLive} className="fbLike" onClick={() => handleShareIconsView(mountDetails.facebookLikeUrl)} />
                                                </button> : null
                                            }
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  </div>
                                </Spin>
                              </Tab>
                              <Tab eventKey="profile" title="Specifications">
                                <Spin spinning={viewStateLoading}>
                                  <div className="tab__contentdiv border__block p-0">
                                    <div className="head__div">
                                      <h4>Diamond Details</h4>
                                    </div>
                                    <div className="desc__maindiv">
                                      {
                                        diamondDetails ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Stock Number
                                            </span>
                                            <span className="desc__txt">
                                              {/* Number{" "} */}
                                              {diamondDetails}
                                            </span>
                                          </div>
                                          : ""
                                      }
                                      {
                                        diamondInfoDetails.fltPrice ?
                                          <div className="desc__div">
                                            <span className="head__txt">Price</span>
                                            <span className="desc__txt">

                                              {diamondInfoDetails.fltPrice == "0" || callForPrice ? <span>Call For Price</span> : <span>
                                                US$
                                                {parseInt(diamondInfoDetails.fltPrice)
                                                  .toFixed()
                                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                              </span>}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.pendantPricePerCarat ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Price Per Carat
                                            </span>
                                            <span className="desc__txt">
                                              $
                                              {diamondInfoDetails.pendantPricePerCarat}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.carat ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Carat Weight{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.carat}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.cut ?
                                          <div className="desc__div">
                                            <span className="head__txt">Cut </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.cut}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.color ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Color{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.color}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.clarity ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Clarity{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.clarity}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.depth ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Depth %{" "}
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.depth
                                                ? diamondInfoDetails.depth + " %"
                                                : "NA"}
                                            </span>
                                          </div> : ""
                                      }

                                      {
                                        diamondInfoDetails.tableMes ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Table %
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.tableMes
                                                ? diamondInfoDetails.tableMes + " %"
                                                : "NA"}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.polish ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Polish
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.polish}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.symmetry ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Symmetry
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.symmetry}
                                            </span>
                                          </div> : ""
                                      }

                                      {
                                        diamondInfoDetails.girdle ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Girdle
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.girdle}
                                            </span>
                                          </div>
                                          : ""
                                      }
                                      {
                                        diamondInfoDetails.culet ?
                                          <div className="desc__div">
                                            <span className="head__txt">Culet</span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.culet}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.fluorescence ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Florescence
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.fluorescence}
                                            </span>
                                          </div> : ""
                                      }
                                      {
                                        diamondInfoDetails.measurements ?
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              Measurement
                                            </span>
                                            <span className="desc__txt">
                                              {diamondInfoDetails.measurements}
                                            </span>
                                          </div> : ""
                                      }

                                    </div>
                                  </div>
                                </Spin>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between aligns-items-center mt-3">
                          <div className="gview__div">
                            <span className="font__bold mr-2 similar__text">
                              {TotalRowCount ? TotalRowCount : 0} Similar Diamonds
                            </span>
                          </div>

                          <div>
                            <Search
                              placeholder="Search Diamond Stock #"
                              onSearch={(e) =>
                                handleSimilarDiamondList(dealerId, completeData, pageNo, pageName, e, pageSize)
                              }
                              allowClear
                              style={{ width: 250 }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mt-4">
                          {/* <Spin spinning={viewStateLoading}> */}
                          <Spin spinning={loadingDiamondGrid}>
                            <div className="lviewprod__tbl">
                              <Table
                                columns={columns}
                                className="info__tooltip__table"
                                // onRow={(record, recordIndex) => ({
                                //   onClick: (event) => {
                                //     handleSetRowDetils(event, record);
                                //   },
                                // })}
                                // rowClassName={(record) =>
                                //   record.intDiamondId == rowDetails.intDiamondId
                                //     ? "row-active"
                                //     : ""
                                // }
                                dataSource={list}
                                // scroll={{ x: 600, y: 300 }}
                                pagination={false}
                              />
                            </div>

                            <div className="table__bottom__btn">
                              {internalUseLinkHide ? (
                                <span
                                  className="font__bold mr-2 mt-3 linkText"
                                  onClick={handleOpenInternalModal}
                                >
                                  For Internal Use Only
                          </span>
                              ) : null}
                              <div>
                                <Pagination
                                  current={pageNo}
                                  pageSize={pageSize}
                                  total={TotalRowCount}
                                  onChange={onChange}
                                  onShowSizeChange={onShowSizeChange}
                                  showSizeChanger="true"
                                />
                              </div>
                            </div>

                            <div className="custom__table print__table">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Shape</th>
                                    <th scope="col">Carats</th>
                                    <th scope="col">Color</th>
                                    <th scope="col">Clarity</th>
                                    <th scope="col">Cut</th>
                                    <th scope="col">Depth</th>
                                    <th scope="col">Table</th>
                                    <th scope="col">Polish</th>
                                    <th scope="col">Symmetry</th>
                                    <th scope="col">Measurement</th>
                                    <th scope="col">Certificate</th>
                                    <th scope="col">Price</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    list.map((row) => (
                                      <tr>
                                        <td><img
                                          style={{ width: "20px", height: "20px" }}
                                          src={row.ImageFileName}
                                          alt=""
                                        /><span>{row.txtShape}</span></td>
                                        <td><span>{row.fltCarat}</span></td>
                                        <td><span>{row.txtColor}</span></td>
                                        <td><span>{row.txtClarity}</span></td>
                                        <td><span>{row.txtCutGrade}</span></td>
                                        <td><span>{row.fltDepth}</span></td>
                                        <td><span>{row.fltTable}</span></td>
                                        <td><span>{row.txtPolish}</span></td>
                                        <td><span>{row.txtSymmetry}</span></td>
                                        <td><span>{row.txtMeasurements}</span></td>
                                        <td><span>{row.txtCertificate}</span></td>
                                        <td>{callForPrice ? (
                                          <span>Call</span>
                                        ) : (
                                            <span>
                                              {row.fltPrice ? "$" : null}
                                              {row.fltPrice}
                                            </span>
                                          )}</td>


                                      </tr>
                                    ))
                                  }


                                </tbody>
                              </table>
                            </div>
                            {/* </Spin> */}
                          </Spin>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Wrapper>



      <React.Fragment>

        {/* Modal Section Starts */}

        {/* Request More Info Modal Starts */}
        <Modal
          title="Request More Info"
          visible={requestMoreModal}
          width={700}
          style={{ top: 20 }}
          className="request_info"
          maskClosable={false}
          onCancel={closeRequestMoreModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeRequestMoreModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitRequestMoreInfo}
            >
              Request
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>Our specialist will contact you.</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.name}
                        placeholder="Enter Your Name"
                        className={
                          requestMoreInfoInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "name", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail Address <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.email}
                        placeholder="Enter Your E-Mail Address"
                        className={
                          requestMoreInfoInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "email", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Phone Number <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={requestMoreInfoInputData.phoneNumber}
                        placeholder="Enter Your Phone Number"
                        className={
                          requestMoreInfoInputDataValidation.phoneNumberval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleRequestMoreInfoChange(e, "phoneNumber", "input")
                        }
                      />
                      {requestMoreInfoInputDataValidation.phoneNumberval && (
                        <p className="error-color-red">
                          {requestMoreInfoInputDataValidation.phoneNumberval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Contact Preference</label>
                      <div className="row">
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byEmail"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                                "byEmail"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>By Email</label>
                        </div>
                        <div className="radio__block col-6">
                          <input
                            type="radio"
                            value="byPhone"
                            checked={
                              requestMoreInfoInputData.contactPreference ==
                                "byPhone"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleRequestMoreInfoChange(
                                e,
                                "contactPreference",
                                "radio"
                              )
                            }
                          />

                          <label>By Phone</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Write A Personal Message ...</label>
                      <textarea
                        value={requestMoreInfoInputData.personalMessage}
                        placeholder="Write A Personal Message ..."
                        onChange={(e) =>
                          handleRequestMoreInfoChange(
                            e,
                            "personalMessage",
                            "radio"
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Request More Info Modal Ends */}

        {/* Drop A Hint Modal Starts */}
        <Modal
          title="Drop A Hint"
          width={700}
          visible={showDropModal}
          style={{ top: 20 }}
          className="drop_a_hint"
          maskClosable={false}
          onCancel={hideDropHintModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={hideDropHintModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              type="primary"
              loading={buttonLoading}
              onClick={handleSubmitDropHint}
            >
              Drop Hint
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>Because you deserve this.</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.name}
                        placeholder="Enter Your Name"
                        className={
                          dropHintInputDataValidation.nameval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "name", "input")
                        }
                      />
                      {dropHintInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.email}
                        placeholder="Enter Your E-Mail"
                        className={
                          dropHintInputDataValidation.emailval && "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "email", "input")
                        }
                      />
                      {dropHintInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Hint Recipient's Name{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.recipientName}
                        placeholder="Enter Hint Recipient's Name "
                        className={
                          dropHintInputDataValidation.recipientNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientName", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientNameval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Hint Recipient's E-Mail{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.recipientEmail}
                        placeholder="Enter Hint Recipient's E-Mail "
                        className={
                          dropHintInputDataValidation.recipientEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "recipientEmail", "input")
                        }
                      />
                      {dropHintInputDataValidation.recipientEmailval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.recipientEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Reason For This Gift{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={dropHintInputData.giftReason}
                        placeholder="Enter Reason For This Gift"
                        className={
                          dropHintInputDataValidation.giftReasonval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftReason", "input")
                        }
                      />
                      {dropHintInputDataValidation.giftReasonval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftReasonval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Gift Deadline <span className="mandatory">*</span>
                      </label>
                      <input
                        type="date"
                        value={dropHintInputData.giftDeadline}
                        className={
                          dropHintInputDataValidation.giftDeadlineval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "giftDeadline", "date")
                        }
                        min={disablePastDate()}
                      />
                      {dropHintInputDataValidation.giftDeadlineval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.giftDeadlineval}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        Add A Personal Message Here ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={dropHintInputData.personalMessage}
                        placeholder="Add A Personal Message Here ..."
                        className={
                          dropHintInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleDropHintChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {dropHintInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {dropHintInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Drop A Hint Modal Ends */}

        {/* E-Mail A Friend Modal Starts */}
        <Modal
          title="E-Mail A Friend"
          width={700}
          visible={emailFriendModal}
          style={{ top: 20 }}
          className="email_a_friend"
          maskClosable={false}
          onCancel={closeEmailFriendModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeEmailFriendModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              type="primary"
              onClick={handleSubmitEmailFriend}
            >
              Send To Friend
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.name}
                        placeholder="Enter Your Name "
                        className={
                          emailFriendInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "name", "input")
                        }
                      />
                      {emailFriendInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your E-Mail <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.email}
                        placeholder="Enter Your E-Mail"
                        className={
                          emailFriendInputDataValidation.emailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "email", "input")
                        }
                      />
                      {emailFriendInputDataValidation.emailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.emailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Friend's Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.friendName}
                        placeholder="Enter Your Friend's Name"
                        className={
                          emailFriendInputDataValidation.friendNameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendName", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendNameval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendNameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Friend's E-Mail{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailFriendInputData.friendEmail}
                        placeholder="Enter Your Friend's E-Mail"
                        className={
                          emailFriendInputDataValidation.friendEmailval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "friendEmail", "input")
                        }
                      />
                      {emailFriendInputDataValidation.friendEmailval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.friendEmailval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>
                        Add A Personal Message Here ...
                        <span className="mandatory">*</span>
                      </label>
                      <textarea
                        value={emailFriendInputData.personalMessage}
                        placeholder="Add A Personal Message Here ..."
                        className={
                          emailFriendInputDataValidation.personalMessageval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleEmailFriendChange(e, "personalMessage", "input")
                        }
                      ></textarea>
                      {emailFriendInputDataValidation.personalMessageval && (
                        <p className="error-color-red">
                          {emailFriendInputDataValidation.personalMessageval}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* E-Mail A Friend Modal Ends */}

        {/* Schedule Viewing Modal Starts */}
        <Modal
          title="Schedule A Viewing"
          width={700}
          style={{ top: 20 }}
          visible={scheduleViewing}
          className="schedule_viewing"
          onCancel={closeScheduleViewingModal}
          maskClosable={false}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeScheduleViewingModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={buttonLoading}
              onClick={handleSubmitSheduleViewing}
            >
              Schedule
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <p>See This Item & More In Our Store</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>
                        Your Name <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.name}
                        placeholder="Enter Your Name "
                        className={
                          scheduleViewingInputDataValidation.nameval &&
                          "border__red"
                        }
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "name", "input")
                        }
                      />
                      {scheduleViewingInputDataValidation.nameval && (
                        <p className="error-color-red">
                          {scheduleViewingInputDataValidation.nameval}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Email</label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.email}
                        placeholder="Enter Your Email"
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "email", "input")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Phone Number</label>
                      <input
                        type="text"
                        value={scheduleViewingInputData.phoneNumber}
                        placeholder="Enter Your Phone Number"
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "phoneNumber", "input")
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Location</label>
                      <Select
                        className="border__grey"
                        name="location"
                        optionFilterProp="children"
                        value={scheduleViewingInputData.location}
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "location", "select")
                        }
                      >
                        <Option value="">Select Location</Option>
                        {locationDetails &&
                          locationDetails.map((item, i) => {
                            return (
                              <Option value={item.LocationId}>
                                {item.LocationName}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>When are you available?</label>
                      <input
                        type="date"
                        value={scheduleViewingInputData.appointmentDate}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentDate",
                            "input"
                          )
                        }
                        min={disablePastDate()}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Appointment Time</label>
                      <Select
                        className="border__grey"
                        name="appointmentTime"
                        optionFilterProp="children"
                        value={scheduleViewingInputData.appointmentTime}
                        onChange={(e) =>
                          handleScheduleViewingChange(
                            e,
                            "appointmentTime",
                            "select"
                          )
                        }
                      >
                        <Option value="">What time do you have open?</Option>
                        {getTimedate &&
                          getTimedate.map((item, i) => {
                            return (
                              <Option value={item.value}>{item.text}</Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                      <div className="input__block">
                        <label>Address</label>
                        <textarea
                          value={scheduleViewingInputData.address}
                          onChange={(e) =>
                            handleScheduleViewingChange(e, "address", "input")
                          }
                        ></textarea>
                      </div>
                    </div> */}

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Add A Personal Message Here ...</label>
                      <textarea
                        value={scheduleViewingInputData.message}
                        placeholder="Add A Personal Message Here ..."
                        onChange={(e) =>
                          handleScheduleViewingChange(e, "message", "input")
                        }
                      ></textarea>
                    </div>
                  </div>
                  {adressHide ? (
                    <div className="col-md-12">
                      <p className="font__bold d-flex justify-between">
                        <span className="subheading">
                          {locationData.locationName
                            ? parse(locationData.locationName)
                            : ""}
                        </span>
                        {/* <span className="subheading">
                                            {locationData && locationData.locationName}
                                            </span> */}
                      </p>
                      <p className="font__bold">
                        <span className="subheading">
                          {locationData.phone ? locationData.phone : ""}
                        </span>
                      </p>
                      <p className="font__bold">
                        <a href={`mailto:${locationData.emailID}`}>
                          {locationData.emailID ? locationData.emailID : ""}
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Schedule Viewing Modal Ends */}
        <Modal
          title="Certificate"
          visible={viewCertModal}
          width={700}
          style={{ top: 20 }}
          className="cert_modal"
          onCancel={closeCertModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeCertModal}>
              Cancel
            </Button>,
            // <Button key="submit" type="primary" onClick={handleSubmitDropHint}>
            //   Drop Hint
            // </Button>,
          ]}
        >
          <div className="row">
            <div>
              <Spin spinning={iframeLoading}>
                <iframe
                  onLoad={hideSpinner}
                  width="100%"
                  height="500px"
                  src={diamondInfoDetails.certlink}
                />
              </Spin>
            </div>
          </div>
        </Modal>

        <Modal
          title="Request Certificate"
          width={700}
          visible={requestCertModal}
          style={{ top: 20 }}
          className="request_info"
          onCancel={closeCertInfoModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={closeCertInfoModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              loading={requestCertModalLoading}
              type="primary"
              onClick={handleSubmitRequestCert}
            >
              Request
            </Button>,
          ]}
        >
          <div className="row">
            <div class="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Name <span className="mandatory">*</span> </label>
                      <input
                        type="text"
                        value={certInfoData.name}
                        className={
                          certInfoValidation.nameVal && "border__red"
                        }
                        onChange={(e) =>
                          handleCertInfoChange(e, "name", "input")
                        }
                      />
                      {certInfoValidation.nameVal && (
                        <p className="error-color-red">
                          {certInfoValidation.nameVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>E-Mail Address <span className="mandatory">*</span> </label>
                      <input
                        type="text"
                        value={certInfoData.email}
                        className={
                          certInfoValidation.emailVal && "border__red"
                        }
                        onChange={(e) =>
                          handleCertInfoChange(e, "email", "input")
                        }
                      />
                      {certInfoValidation.emailVal && (
                        <p className="error-color-red">
                          {certInfoValidation.emailVal}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Phone Number <span className="mandatory">*</span> </label>
                      <input
                        type="text"
                        value={certInfoData.phoneNumber}
                        className={
                          certInfoValidation.phoneNumberVal && "border__red"
                        }
                        onChange={(e) =>
                          handleCertInfoChange(e, "phoneNumber", "input")
                        }
                      />
                      {certInfoValidation.phoneNumberVal && (
                        <p className="error-color-red">
                          {certInfoValidation.phoneNumberVal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Comments</label>
                      <textarea
                        value={certInfoData.comments}
                        onChange={(e) =>
                          handleCertInfoChange(e, "comments", "input")
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Thumbnail Slider Video Modal Starts */}
        <Modal
          title=""
          style={{ top: 20 }}
          visible={isModalVisible6}
          className="video__modal"
          onCancel={handleCancel6}
          footer={false}
        >
          <div className="video__section">
            <video
              width="100%"
              height="80%"
              src="https://up.diacam360.com/scan/90ad_1052947"
              autoPlay
              loop
              controls
            ></video>
          </div>
        </Modal>
        {/* Thumbnail Slider Video Modal Ends */}

        {/* For Internal Use Only Modal Starts */}

        <Modal
          title="For Internal Use Only"
          width={700}
          style={{ top: 20 }}
          visible={internalUseModal}
          className="video__modal"
          onCancel={handleCancelInternalModal}
          footer={[
            <Button key="back"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={handleCancelInternalModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              loading={internalUseModalLoading}
              type="primary"
              style={{
                background: `${varColorObj.callToActionColor}`,
                color: "#ffffff",
              }}
              onClick={HandleCallForInternal}
            >
              Submit
            </Button>,
          ]}
        >
          {showDealerInfo == true ? (
            <div className="form__fields border-0 mh-aut0">
              {dealerInfo &&
                dealerInfo.map(
                  (item, i) => {
                    return (
                      <div>
                        <p>
                          Vendor Name:{" "}
                          {item.vendorName
                            ? item.vendorName
                            : "NA"}
                        </p>
                        <p>
                          Vendor City/State :{" "}
                          {(item.city) ||
                            (item.state)
                            ? item.city + "/" + item.state
                            : "NA"}
                        </p>
                        <p>
                          Vendor Phone Number:{" "}
                          {item.dealerPhone
                            ? item.dealerPhone
                            : "NA"}
                        </p>
                        <p>
                          Vendor E-Mail:{" "}
                          {item.dealerEmail
                            ? item.dealerEmail
                            : "NA"}
                        </p>
                        <p>
                          Vendor lot number of the item:{" "}
                          {item.dealerInventoryNo
                            ? item.dealerInventoryNo
                            : "NA"}
                        </p>
                        <p>
                          WholeSale Price:{" "}
                          {item.dealerCost
                            ? item.dealerCost
                            : "NA"}
                        </p>
                        {/* <p> Vendor lot number of the item: {}</p> */}
                        <p> WholeSale Price: {}</p>
                        {/* <p> Third Party: {}</p>
                        <p> Diamond ID: {}</p>
                        <p> Seller Name: {}</p>
                        <p> Address: {}</p> */}
                      </div>

                    );
                  }
                )}

              {/* <p>
                Vendor Name:{" "}
                {dealerInfo && dealerInfo.vendorName
                  ? dealerInfo.vendorName
                  : "NA"}
              </p> */}


            </div>
          ) : (
              <div className="form__fields">
                <div className="col-md-6">
                  <div className="input__block">
                    <label htmlFor="">
                      GF Password <span className="mandatory">*</span>
                    </label>
                    <input
                      type="password"
                      value={internalUseData.password}
                      className={internalUseDataval.passwordval && "border__red"}
                      onChange={(e) =>
                        handlepasswordMoreInfoChange(e, "password", "input")
                      }
                    />
                    {internalUseDataval.passwordval && (
                      <p className="error-color-red">
                        {internalUseDataval.passwordval}
                      </p>
                    )}
                  </div>
                  <span
                    className="reset__pass"
                    onClick={() =>
                      setInternalUseData({
                        password: "",
                      })
                    }
                  >
                    Reset Password
                </span>
                </div>
              </div>
            )}
        </Modal>

        {/* For Internal Use Only Modal Ends */}

        {/* Modal Section Ends */}
      </React.Fragment>
    </React.Fragment>
  );
};

export default ViewDiamondDetails;
