// export const VIEW_DIAMOND_DETAILS_PAGE = "VIEW_DIAMOND_DETAILS_PAGE";
// export const HIDE_DIAMOND_DETAILS_PAGE = "HIDE_DIAMOND_DETAILS_PAGE";
// export const SET_COMPARE_TAB_IDS = "SET_COMPARE_TAB_IDS";
// export const SET_DIAMOND_ACTIVE_TAB = "SET_DIAMOND_ACTIVE_TAB";

export const VIEW_DIAMOND_DETAILS_PAGE = "VIEW_DIAMOND_DETAILS_PAGE";
export const HIDE_DIAMOND_DETAILS_PAGE = "HIDE_DIAMOND_DETAILS_PAGE";
export const SET_RESETMINED_TAB = "SET_RESETMINED_TAB";
export const SET_RESETLAB_TAB = "SET_RESETLAB_TAB";
export const SET_RESETFANCY_TAB = "SET_RESETFANCY_TAB";
export const SET_COMPARE_TAB_IDS = "SET_COMPARE_TAB_IDS";
export const SET_DIAMOND_DETAILS = "SET_DIAMOND_DETAILS";
export const SET_RESETREQUEST_TAB = "SET_RESETREQUEST_TAB";
export const SET_DIAMOND_ACTIVE_TAB = "SET_DIAMOND_ACTIVE_TAB";
export const SET_COMPARE_PAGE_KEY = "SET_COMPARE_PAGE_KEY";

//Mined Save and Reset
export const SET_SAVESEARCH_MINEDTAB = "SET_SAVESEARCH_MINEDTAB";
export const SET_SAVEFILTER_MINEDTAB = "SET_SAVEFILTER_MINEDTAB";
export const SET_SAVEEVERYTIME_MINEDTAB = "SET_SAVEEVERYTIME_MINEDTAB";
export const SET_SWITCHFILTER_MINEDTAB = "SET_SWITCHFILTER_MINEDTAB";
//Lab save and reset
export const SET_SAVESEARCH_LABTAB = "SET_SAVESEARCH_LABTAB";
export const SET_SAVEFILTER_LABTAB = "SET_SAVEFILTER_LABTAB";
export const SET_SAVEEVERYTIME_LABTAB = "SET_SAVEEVERYTIME_LABTAB";
export const SET_SWITCHFILTER_LABTAB = "SET_SWITCHFILTER_LABTAB";

//Fancy save and reset
export const SET_SAVESEARCH_FANCYTAB = "SET_SAVESEARCH_FANCYTAB"; // For reset or save - true and false toggle
export const SET_SAVEFILTER_FANCYTAB = "SET_SAVEFILTER_FANCYTAB"; // For payload set fancyfilter -- main
export const SET_SAVEEVERYTIME_FANCYTAB = "SET_SAVEEVERYTIME_FANCYTAB"; // Every time filter save - dummy
export const SET_SWITCHFILTER_FANCYTAB = "SET_SWITCHFILTER_FANCYTAB"; // Switch dummy to main
