import React, { useState, useEffect } from "react";
import { Input, Row, Col, Select, Checkbox, Spin, Modal, Button } from "antd";

import Eye_icon from "../../../assets/images/icons/eye_icon.svg";
import CompareImg from "../../../assets/images/compare-diamond.png";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import roundDiamond from "../../../assets/images/daimond-link.jpg";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import moment from "moment";
import { handleViewDetailsPage } from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useLocation, useNavigate } from "react-router-dom";

export const Compare = ({ props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CompareData = useSelector(
    (state) => state.selectYourDiamondReducer.compareKey
  );
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const ParamCompareIds = new URLSearchParams(search).get("CompareIds");
  const PageName = new URLSearchParams(search).get("PageName");
  // const ParamcompareKeys = new URLSearchParams(search).get("compareKey");

  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const [filterVals, setFilterVals] = useState({
    Shape: [],
  });

  const [keyValue, setKeyValue] = useState([
    "bigImageFileName",
    "txtShape",
    "intDiamondId",
    "fltCarat",
    "txtColor",
    "fltTable",
    "modifiedDate",
    "txtPolish",
    "txtSymmetry",
    "txtClarity",
    "txtFluorescence",
    "fltDepth",
    "txtMeasurements",
    "txtCertificate",
    "txtCutGrade",
    "realPrice",
    "action",
  ]);
  const [loading, setLoading] = useState(false);
  const [compareList, setCompareList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [viewCertModal, setviewCertModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [certRow, setCertRow] = useState({});
  const [dummyData, setDummyData] = useState([
    {
      id: 1,
      img: roundDiamond,
      Shape: "Round",
      SKU: "Mike",
      Carat: 32,
      Table: "10 Downing Street",
      Polish: "Excellent",
      Symmetry: "Excellent",
      Clarity: "D",
      Fluorescence: "None",
      Depth: "60%",
      Measurement: "4.31*4.35*2.64",
      Cert: "IGI",
      Cut: "Ideal",
      Price: "$183",
      Action: "",
    },
    {
      id: 2,
      img: roundDiamond,
      Shape: "Oval",
      SKU: "Mike",
      Carat: 32,
      Table: "10 Downing Street",
      Polish: "Excellent",
      Symmetry: "Excellent",
      Clarity: "D",
      Fluorescence: "None",
      Depth: "60%",
      Measurement: "4.31*4.35*2.64",
      Cert: "IGI",
      Cut: "Ideal",
      Price: "$183",
      Action: "",
    },
    {
      id: 3,
      img: roundDiamond,
      Shape: "Round",
      SKU: "Mike",
      Carat: 32,
      Table: "10 Downing Street",
      Polish: "Excellent",
      Symmetry: "Excellent",
      Clarity: "D",
      Fluorescence: "None",
      Depth: "60%",
      Measurement: "4.31*4.35*2.64",
      Cert: "IGI",
      Cut: "Ideal",
      Price: "$183",
      Action: "",
    },
  ]);

  useEffect(() => {
    if (paramdealerId) {
      if (CompareData == "Mined") {
        if (ParamCompareIds) {
          handleLoadCompareMinedDiamondsList(
            paramdealerId,
            ParamCompareIds
          );
          // handleLoadCompareDiamondsList(paramdealerId, ParamCompareIds);
        }

      }
      else {
        handleLoadCompareLabDiamondsList(
          paramdealerId,
          ParamCompareIds
        );
        // handleLoadCompareDiamondsList(paramdealerId, ParamCompareIds);
      }
      setDealerId(paramdealerId);
    }

  }, []);

  // useEffect(() => {
  //   if (loginDetails) {
  //     if (loginDetails.dealerId) {
  //       if (CompareData) {
  //         if (CompareData.pageName) {
  //           if (CompareData.pageName == "Mined") {
  //             if (CompareData.ids) {
  //               if (CompareData.ids.length > 0) {
  //                 handleLoadCompareMinedDiamondsList(
  //                   loginDetails.dealerId,
  //                   CompareData.ids
  //                 );
  //               }
  //             }
  //           } else {
  //             if (CompareData.ids) {
  //               if (CompareData.ids.length > 0) {
  //                 handleLoadCompareLabDiamondsList(
  //                   loginDetails.dealerId,
  //                   CompareData.ids
  //                 );
  //               }
  //             }
  //           }
  //         }
  //       }
  //       setDealerId(loginDetails.dealerId);
  //     }
  //   }
  // }, []);

  const handleLoadCompareMinedDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondsList: CompareData.toString(),
        cType: "D",
      };

      setLoading(true);
      SelectYourDiamondService.LoadCompareDiamondsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.Table1;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleLoadCompareLabDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondId: CompareData.toString(),
        flag: "",
        orderBy: "",
        orderType: "",
      };

      setLoading(true);
      SelectYourDiamondService.GetRingBuilderLabCompareData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleViewDetails = (details) => {
    var obj = details;
    // obj["pageName"] = "Mined";
    obj["shapeFilters"] = filterVals.Shape;
    // dispatch(handleShowViewPage());
    // dispatch(handleSetDiamondDetails(obj));
    dispatch(handleViewDetailsPage(obj));
    navigate(
      "/viewDiamondDetails" +
      `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${PageName}&shapeFilters=${filterVals.Shape}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    window.parent.postMessage("submitted form", "*");
  };

  const handleDeleteCol = (id) => {

    var filteredArr = compareList.filter((item) => item.intDiamondId !== id);
    setCompareList(filteredArr);
  };

  const handleDelRow = (val) => {
    var filteredArr = keyValue.filter((item) => item !== val);
    setKeyValue(filteredArr);
  };

  const handleCertModalOpen = (row) => {
    setCertRow(row);
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setCertRow({});
    setviewCertModal(false);
  };

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  return (
    <React.Fragment>
      {/* <span className="back__btn"> {"<< Change Diamond"} </span> */}
      {/* <Wrapper> */}
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content border__block">
          <div className="diamond__details filter_section mt-3">
            <Spin spinning={loading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="compare__table custom__table">
                    <table class="table  table-striped">
                      <tbody>
                        {keyValue.map((val) => (
                          <tr>
                            <th>
                              <div className="headingtable">
                                {val == "bigImageFileName" ? (
                                  ""
                                ) : val == "action" ? (
                                  ""
                                ) : (
                                      <React.Fragment>
                                        <i
                                          class="fa fa-times-circle-o"
                                          aria-hidden="true"
                                          onClick={() => handleDelRow(val)}
                                        ></i>
                                        <span>
                                          {val == "txtShape"
                                            ? "Shape"
                                            : val == "intDiamondId"
                                              ? "SKU"
                                              : val == "fltCarat"
                                                ? "Carat"
                                                : val == "txtColor"
                                                  ? "Color"
                                                  : val == "fltTable"
                                                    ? "Table"
                                                    : val == "modifiedDate"
                                                      ? "Date Added"
                                                      : val == "fltDepth"
                                                        ? "Depth"
                                                        : val == "txtPolish"
                                                          ? "Polish"
                                                          : val == "txtSymmetry"
                                                            ? "Symmetry"
                                                            : val == "txtClarity"
                                                              ? "Clarity"
                                                              : val == "txtFluorescence"
                                                                ? "Fluorescence"
                                                                : val == "txtFluorescence"
                                                                  ? "Fluorescence"
                                                                  : val == "txtMeasurements"
                                                                    ? "Measurements"
                                                                    : val == "txtCertificate"
                                                                      ? "Certificate"
                                                                      : val == "txtCutGrade"
                                                                        ? "Cut Grade"
                                                                        : val == "realPrice"
                                                                          ? "Price"
                                                                          : val}
                                        </span>
                                      </React.Fragment>
                                    )}
                              </div>
                            </th>
                            {compareList.map((item) => {
                              return val == "bigImageFileName" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      style={{ width: "120px" }}
                                      src={item[val] ? item[val] : item.image}
                                    />
                                  </td>
                                </React.Fragment>
                              ) : val == "modifiedDate" ? (
                                <React.Fragment>
                                  <td>
                                    {moment(item[val]).format("MM/DD/YYYY")}
                                  </td>
                                </React.Fragment>
                              ) : val == "action" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      src={Eye_icon}
                                      onClick={() => handleViewDetails(item)}
                                      className="mr-2 icon__width Hand__pointer linktextmouse"
                                    />
                                    <img
                                      src={Delete_iconbl}
                                      onClick={() =>
                                        handleDeleteCol(item.intDiamondId)
                                      }
                                      className="icon__width Hand__pointer linktextmouse"
                                    />
                                  </td>
                                </React.Fragment>
                              ) : (
                                      <React.Fragment>
                                        <td>
                                          {val == "txtCertificate" ? (
                                            <p
                                              onClick={() =>
                                                handleCertModalOpen(item)
                                              }
                                              className="link-Text"
                                            >
                                              {item[val]}
                                            </p>
                                          ) : (
                                              item[val]
                                            )}
                                        </td>
                                      </React.Fragment>
                                    );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row">
              <div className="col-md-8">
                <div className="text__block">
                  <p>
                    Use your diamond comparison to save all the details of the
                    diamonds you are considering and view them all together.
                  </p>
                  <p>This makes it easy to choose the right diamond.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image__block">
                  <img src={CompareImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Wrapper> */}
      <Modal
        title="Certificate"
        visible={viewCertModal}
        style={{ top: 20 }}
        className="cert_modal"
        onCancel={closeCertModal}
        footer={[
          <Button key="back" onClick={closeCertModal}>
            Cancel
          </Button>,
        ]}
      >
        <div className="row">
          <div>
            <Spin spinning={iframeLoading}>
              <iframe
                width="100%"
                height="450"
                onLoad={hideSpinner}
                src={certRow.certificateLink}
              />
            </Spin>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Compare;
